import { APIServiceService } from 'src/app/api/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';

declare let $;

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.css']
})
export class ChangePassComponent implements OnInit {

  errorStatus = true;
  errorMSG: string = "";
  errorActive: boolean;

  constructor(private serviceCall: APIServiceService, private router: Router, private cookie: CookieService, private location: Location) { }

  ngOnInit() {

    this.serviceCall.isMenuActive = true;

    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $('#imagePreview').css('background-image', 'url(' + e.target + ')');
          $('#imagePreview').hide();
          $('#imagePreview').fadeIn(400);
        }
        reader.readAsDataURL(input.files[0]);
      }
    }

    $("#imageUpload").change(function () {
      readURL(this);
    });

    $('.deleteaddress').click(function (e) {
      $(this).parent('li').remove();
      return false;
    });

    $('.fixlabelinput .form-group label').each(function () {
      var paddingWidth = $(this).width();
      $(this).next('input').css('padding-left', paddingWidth + 18 + 'px');
    });

    $('.fixlabelinput .form-group label').each(function () {
      var paddingWidth = $(this).width();
      $(this).next('select').css('padding-left', paddingWidth + 18 + 'px');
    })

    $('.fixlabelinput .form-group label').each(function () {
      var paddingWidth = $(this).width();
      $(this).next('textarea').css('padding-left', paddingWidth + 18 + 'px');
    });

    $('.passwordicon').click(function () {
      $(this).toggleClass('hide-password');
      if ($(this).hasClass('hide-password')) {
        $(this).prev('input').attr('type', 'text');
      } else {
        $(this).prev('input').attr('type', 'password');
      }
    });
  }

  /*
    * change password with validation by api.
    * @param user-id, old-pass, new-pass, token-key: string 
    * @return no-return
    * @author Deepak Alram
  */
  public changePass(e) {

    e.preventDefault();

    let patent = /^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/;
    if (!e.target.newPass.value.match(patent) || !e.target.cnewPass.value.match(patent)) {
      this.errorStatus = false;
      this.errorMSG = "invalid password && confirm password (not match requirement)";
      e.target.newPass.focus();

    }
    else if (!e.target.newPass.value.match(e.target.cnewPass.value)) {
      this.errorStatus = false;
      this.errorMSG = "password not match with confirm password";
      e.target.newPass.focus();
    }
    else {

      let user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
      let info = {
        userId: user.id,
        oldPassword: e.target.oldPass.value,
        newPassword: e.target.newPass.value,
        token: user.token_key,
      }
      this.serviceCall._changePassword(info).subscribe(res => {
        let tempData: any = res;
        if (tempData.status == 1) {
          this.errorActive = true;
          this.errorMSG = tempData.message
          this.errorStatus = false;
          setTimeout(() => {
            this.errorStatus = true;
            this.errorActive = false;
            this.errorMSG = "";
          }, 2000);
        }
        else { console.log(tempData.message); }
      },
        err => {
          if (err.status === 401) {
            this.serviceCall._conditionalLogout();
          }
        });
    }
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }
}
