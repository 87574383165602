import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APIServiceService } from '../api/api.service';


@Injectable({
  providedIn: 'root'
})
export class RouteAuthenticationGuard implements CanActivate {

  constructor(private serviceCall: APIServiceService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.serviceCall.isLogin) {
      return true;
    }
    else {
      return this.router.parseUrl("/login");
    }
  }

}
