import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resetpass-page',
  templateUrl: './resetpass-page.component.html',
  styleUrls: ['./resetpass-page.component.css']
})
export class ResetpassPageComponent implements OnInit {

  tokenTemp: string;
  errorStatus: boolean = true;
  errorMSG: string;

  constructor(private serviceCall: APIServiceService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
  }

  /*
		* _resetPassword call.
		* @param  event:obj.
		* @return no-return.
		* @author AD.
	*/
  public resetPassword(e) {

    e.preventDefault();
    let patent = /^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/;

    if (!e.target.newPassword.value.match(patent) && !e.target.confirmPassword.value.match(patent)) {
      this.errorStatus = false;
      this.errorMSG = "invalid password && confirm password (not match requirement)";
    }
    else if (!e.target.newPassword.value.match(e.target.confirmPassword.value)) {
      this.errorStatus = false;
      this.errorMSG = "password not match with confirm password";
    }
    else {
      this.activeRoute.params.subscribe(params => this.tokenTemp = params.token);
      let decodeString = atob(this.tokenTemp);
      let splitTemp: any = decodeString.split('|');
      let resDate: any = new Date(splitTemp[1]).setHours(new Date(splitTemp[1]).getHours() + 1);

      if (new Date() > resDate) {
        this.errorStatus = false;
        this.errorMSG = 'Link Expired';
      }
      else {
        let info={
          email:splitTemp[0],
          npassword:e.target.newPassword.value,
        }
        this.serviceCall._resetPassword(info).subscribe(
          res => {
            let tempData: any = res;
            if (tempData.status == 1) {
              this.errorStatus = false;
              this.errorMSG = tempData.message;
            }
            else {
              this.errorStatus = false;
              this.errorMSG = tempData.message;
            }
          });
      }
    }
  }

}
