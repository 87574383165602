import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-pass-page',
  templateUrl: './forgot-pass-page.component.html',
  styleUrls: ['./forgot-pass-page.component.css']
})
export class ForgotPassPageComponent implements OnInit {

  message: string = null;
  errorStatus: boolean = true;
  errorMSG: string;

  constructor(private serviceCall: APIServiceService, private router: Router) { }

  ngOnInit() {
  }

  /*
    * _forgotPassword password.
    * @param info:obj.
    * @return no-return
    * @author Deepak Alram
  */
  public forgot(e) {
    e.preventDefault();

    let info={
      email:e.target.email.value,
      device_type:3
    }

    this.serviceCall._forgotPassword(info).subscribe(
      (res) => {
        let tempData: any = res;
        
        if (tempData.status === 1) {

          /* Hide error */
          this.errorStatus = true;
          this.errorMSG = "";

          /* form fields reset */
          e.target.reset();
       
          this.message = tempData.message;
          
          /* reset message var */
          setTimeout(() => {
            this.message = null
          }, 3000);
       
        }
        else {
          /* show error */
          this.errorMSG = tempData.message;
          this.errorStatus = false;
        };
      })
  }
}
