import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
declare let $;

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
export class ProductViewComponent implements OnInit {

  productInfo: any = {};
  productID: number;
  quantity: any;
  pickUpAllAddres: any = [];
  pickUpAddress: number;
  addressType: number;
  showPrice: boolean = false;
  popupShow: boolean = true;
  shimmer: boolean = false;
  sproducts: any[] = [];
  show: boolean = false;
  Breadcrumb: any[] = [];
  localCart: any = {
    userId: 0,
    localCount: 0,
    cartData: {
      pickup: [],
      delivery: []
    }
  }
  unitData: any;
  selectedUnitData = 0;
  constructor(
    private serviceCall: APIServiceService,
    private ActivatedRoute: ActivatedRoute,
    private router: Router,
    private cookie: CookieService,
    private location: Location,
    private translate: TranslateService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.showPrice = this.cookie.check(window.location.hostname) ? false : true;
    this.ActivatedRoute.params.subscribe(params => this.productID = params.token)
    if (this.cookie.check(window.location.hostname + 'local')) {
      this.localCart = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')));
    } else if (this.cookie.check(window.location.hostname)) {
      this.localCart = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    }
    if (sessionStorage.getItem('breadcrumb')) this.Breadcrumb = JSON.parse(atob(sessionStorage.getItem('breadcrumb')));
    let self = this;
    $(document).on('change', ".pickupadddropclick", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('input[name="address-radio"]').prop("checked", false);
      if ($(this).attr('for') == 'pickup-checkbox') {
        self.addressType = 1;
        self.pickupDeliveryAddress();
      }
      else {
        self.addressType = 2;
      }
      if ($(this).attr('for') == "pickup-checkbox") {
        $(this).parent('.checkbox').find('.pickupadddrop').slideDown();
      } else {
        $('.pickupadddrop').slideUp();
      }
      $(this).find('input').prop("checked", true);
      return false;
    });
    $(document).on('change', 'label[for="pickupadddline1"]', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var forAttr = $(this).find('span').attr('id');
      self.pickUpAddress = forAttr;
    });
    this.productInfoFetch();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let self = this;
      $(window).on("resize", function () {
        if ($('.popouterbox').length && $('.popouterbox').is(':visible')) {
          var popheighton = $('.popouterbox .popup-block').height() + 60;
          var winHeight = $(window).height();
          if (popheighton > winHeight) {
            $('.popouterbox .modal-backdrop').height(popheighton);
            $('.popouterbox .popup-block').removeAttr('style').addClass('taller');
          } else {
            $('.popouterbox .modal-backdrop').height('100%');
            $('.popouterbox .popup-block').removeClass('taller');
            $('.popouterbox .popup-block').css({
              'margin-top': -(popheighton / 2)
            });
          }
        }
      });
      $(document).on('click', '.close-popup, .modal-backdrop', function () {
        $(this).parents('.popouterbox').fadeOut(300, function () {
          $(this).find('.modal-backdrop').fadeOut(250, function () {
            $('body').removeClass('overflowhidden');
            $('.popouterbox .popup-block').removeAttr('style');
            $(this).remove();
          });
        });
        return false;
      });
      $('.add').click(function () {
        if ($(this).prev().val()) {
          $(this).prev().val(+$(this).prev().val() + 1);
          self.quantity = $(this).next().val();
        }
      });
      $('.sub').click(function () {
        if ($(this).next().val() > 1) {
          if ($(this).next().val() > 1) {
            $(this).next().val(+$(this).next().val() - 1);
            self.quantity = $(this).next().val();
          }
        }
      });
    }, 2000);
  }

  /*
    * _productInfo call.
    * @param info:obj. 
    * @return api response
    * @author AD
  */
  public productInfoFetch() {
    this.shimmer = false;
    let userId;
    if (this.cookie.check(window.location.hostname)) { userId = JSON.parse(atob(this.cookie.get(window.location.hostname))) }
    let info = {
      productId: this.productID,
      langId: this.serviceCall._languagePass(sessionStorage.getItem('lan')),
      user_id: userId ? userId.id : 0
    }
    this.serviceCall._productInfo(info).subscribe(
      res => {
        console.log('product detail', res);
        let tempData: any = res;
        if (tempData.status == 1) {
          this.productInfo = tempData.data;
          this.unitData = this.productInfo.unitdata[0];
          this.getSimilarProducts(this.productInfo.id, this.productInfo.category_id, info.langId)
        }
        else console.log(tempData.message);
        this.productInfo.already_pickup ? this.popupShow = false : this.popupShow = true;
        this.shimmer = true;
      },
      err => {
        console.log(err.status);
      });
  }

  public check() {
    if (!this.cookie.check(window.location.hostname)) {
      this.popUpCall('selectuser');
      //this.show = true;
      //this.popUpCall('shippingtype');
    } else {
      this.popUpCall('shippingtype');
      // this.addToCart();
    }
    //this.router.navigate(['select-address']);
    // this.popUpCall('shippingtype');
  }

  /*
    * _addTOCart call.
    * @param info:obj. 
    * @return api response.
    * @author AD.
  */
  public addToCart() {
    if (this.addressType == 2) this.pickUpAddress = null;
    if (this.quantity == undefined || this.quantity == null) {
      let tempRef: HTMLInputElement = document.querySelector('#quantity');
      this.quantity = tempRef.value;
    }
    let user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    let info = {
      productId: this.productID,
      userId: user.id,
      quantity: this.quantity,
      tab_type: this.addressType,
      address_id: this.pickUpAddress || 0,
      token: user.token_key,
      unity: this.unitData.id
    }
    this.serviceCall._addTOCart(info).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status == 1) {
          if (tempData.add_flag) {
            user.cart_count = parseInt(user.cart_count) + 1;
            /* update cookie */
            this.cookie.set(window.location.hostname, btoa(JSON.stringify(user)));
          }
          this.htmlpopup();
          Swal.fire({
            icon: 'success',
            title: 'product added in a cart.',
            showConfirmButton: false,
            timer: 1500
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: tempData.message,
          });
        }
      },
      err => {
        if (err.status === 401) {
          this.serviceCall._conditionalLogout();
        }
      });
  }

  /*
    * _getPickUpAddress call.
    * @param info:obj. 
    * @return api response.
    * @author AD.
  */
  public pickupDeliveryAddress() {
    let user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    let info = {
      userId: user.id,
      token: user.token_key,
    }
    this.serviceCall._getPickUpAddress(info).subscribe(
      res => {
        let tempData: any = res;
        tempData.status == 1 ? this.pickUpAllAddres = tempData.data : console.log(tempData.message);
      },
      err => {
        if (err.status === 401) {
          this.serviceCall._conditionalLogout();
        }
      });
  }

  /*
    * _getSimilarProducts call.
    * @param info:obj.
    * @return api response.
    * @author AD.
    * for now not use.
  */
  public getSimilarProducts(cateid, proid, langid) {
    this.serviceCall._getSimilarProducts({ categoryId: cateid, productId: proid, langId: langid }).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status === 1) { this.sproducts = tempData.data; }
        else console.log(tempData.message);
      })
  }

  /*
    * _addTOCart call.
    * @param info:obj. 
    * @return api response.
    * @author AD.
  */
  public addToCartWithoutLogin(type, e) {
    if (this.quantity == undefined || this.quantity == null) {
      let tempRef: HTMLInputElement = document.querySelector('#quantity');
      this.quantity = tempRef.value;
    }
    let info = {
      productId: this.productID,
      userId: 0,
      quantity: this.quantity,
      tab_type: type,
      address_id: 0,
      token: '',
      unity: this.unitData.id
    }
    this.serviceCall._addTOCart(info).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status == 1) {
          /* For pickup */
          if (info.tab_type == 1) {
            let pp = {
              lpid: this.productInfo.id,
              product_image: this.productInfo.product_image,
              category_name: this.productInfo.category_name,
              product_name: this.productInfo.product_name,
              nominal_size_name: this.productInfo.nominal_size_name,
              product_packages: this.quantity,
              actual_packages: parseInt(this.productInfo.unity_pallet),
              package_unity: tempData.data.package_unity,
              price: parseFloat(this.productInfo.price).toFixed(2),
              discount_price: this.productInfo.discount,
              product_quantitywise_price: parseFloat(tempData.data.product_quantitywise_price).toFixed(2),
              unity: this.unitData.id
            }
            this.checkproduct(1, this.productInfo.id, this.localCart, pp);
          }

          /* For delivery */
          if (info.tab_type == 2) {
            let dp = {
              lpid: this.productInfo.id,
              product_image: this.productInfo.product_image,
              category_name: this.productInfo.category_name,
              product_name: this.productInfo.product_name,
              nominal_size_name: this.productInfo.nominal_size_name,
              product_packages: this.quantity,
              actual_packages: parseInt(this.productInfo.unity_pallet),
              package_unity: tempData.data.package_unity,
              price: parseFloat(this.productInfo.price).toFixed(2),
              discount_price: this.productInfo.discount,
              product_quantitywise_price: parseFloat(tempData.data.product_quantitywise_price).toFixed(2),
              unity: this.unitData.id
            }
            this.checkproduct(2, this.productInfo.id, this.localCart, dp);
          }
          this.cookie.set(window.location.hostname + 'local', btoa(JSON.stringify(this.localCart)));
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: tempData.message,
          })
        }
        e.target.closest("form").reset();
        this.show = false;
      },
      err => {
        if (err.status === 401) {
          this.serviceCall._conditionalLogout();
        }
      });
  }

  /* get user role */
  public getUserRole(role, e) {
    switch (role) {
      case 0:
        this.show = true;
        //this.router.navigate(['select-address']);
        break;
      case 1:
        this.router.navigate(['login']);
        break;
      default: console.log("user role not selected");
        break;
    }
  }

  /* convert value in float anf to digit fixed */
  public getWeight(data) {
    return (parseFloat(data)).toFixed(2);
  }

  /* Get actual price */
  public getActualPrice(price, discount) {
    return ((parseFloat(price)) - (parseFloat(discount))).toFixed(2);
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }

  /* popUp Call */
  public popUpCall(data) {
    var popID = data;
    var winHeight = $(window).height();
    $('#' + popID).fadeIn();
    var popheight = $('#' + popID).find('.popup-block').outerHeight(true);
    if ($('.popup-block').length) {
      var popMargTop = popheight / 2;
      if (winHeight > popheight) {
        $('#' + popID).find('.popup-block').css({
          'margin-top': -popMargTop,
        });
      } else {
        $('#' + popID).find('.popup-block').css({
          'top': 0,
        });
      }
    }
    $('#' + popID).append("<div class='modal-backdrop'></div>");
    $('.popouterbox .modal-backdrop').fadeTo("slow", 0.70);
    $('.custom-pop-box .modal-backdrop').fadeTo("fast", 0.88);
    if (popheight > winHeight) {
      $('.popouterbox .modal-backdrop').height(popheight);
    }
    $('#' + popID).focus();
  }

  public checkproduct(type, id, data, product) {
    switch (type) {
      case 1:
        this.htmlpopup();
        if (data.cartData.pickup.length > 0) {
          data.cartData.pickup.forEach(
            (pp, pi) => {
              if (!(parseInt(pp.lpid) === parseInt(id))) {
                if (data.cartData.delivery.length > 0) {
                  data.cartData.delivery.forEach(
                    (dp, di) => {
                      if (parseInt(dp.lpid) === parseInt(id)) {
                        data.cartData.delivery.splice(di, 1);
                        data.cartData.pickup.push(product);
                        Swal.fire({
                          icon: 'success',
                          title: 'product update successfully',
                          showConfirmButton: false,
                          timer: 1500
                        })
                      }
                      else {
                        data.cartData.pickup.push(product);
                        this.localCart.localCount += 1;
                        Swal.fire({
                          icon: 'success',
                          title: 'product added in a cart.',
                          showConfirmButton: false,
                          timer: 1500
                        })
                      };
                    });
                }
                else {
                  data.cartData.pickup.push(product);
                  this.localCart.localCount += 1;
                  Swal.fire({
                    icon: 'success',
                    title: 'product added in a cart.',
                    showConfirmButton: false,
                    timer: 1500
                  })

                }
              }
              else {
                data.cartData.pickup[pi] = product;
                Swal.fire({
                  icon: 'success',
                  title: 'product update successfully',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            });
        }
        else {
          if (data.cartData.delivery.length > 0) {
            data.cartData.delivery.forEach(
              (dp, di) => {
                if (parseInt(dp.lpid) === parseInt(id)) {
                  data.cartData.delivery.splice(di, 1);
                  data.cartData.pickup.push(product);
                  Swal.fire({
                    icon: 'success',
                    title: 'product update successfully',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
                else {
                  data.cartData.pickup.push(product);
                  this.localCart.localCount += 1;
                  Swal.fire({
                    icon: 'success',
                    title: 'product added in a cart.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                };
              });
          }
          else {
            data.cartData.pickup.push(product);
            this.localCart.localCount += 1;
            Swal.fire({
              icon: 'success',
              title: 'product added in a cart.',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        break;
      case 2:
        this.htmlpopup();
        if (data.cartData.delivery.length > 0) {
          data.cartData.delivery.forEach(
            (dp, di) => {
              if (!(parseInt(dp.lpid) === parseInt(id))) {

                if (data.cartData.pickup.length > 0) {
                  data.cartData.pickup.forEach(
                    (pp, pi) => {
                      if (parseInt(pp.lpid) === parseInt(id)) {
                        data.cartData.pickup.splice(pi, 1);
                        data.cartData.delivery.push(product);
                        Swal.fire({
                          icon: 'success',
                          title: 'product update successfully.',
                          showConfirmButton: false,
                          timer: 1500
                        })
                      }
                      else {
                        data.cartData.delivery.push(product);
                        this.localCart.localCount += 1;
                        Swal.fire({
                          icon: 'success',
                          title: 'product added in a cart.',
                          showConfirmButton: false,
                          timer: 1500
                        })
                      }
                    });
                }
                else {
                  data.cartData.delivery.push(product);
                  this.localCart.localCount += 1;
                  Swal.fire({
                    icon: 'success',
                    title: 'product added in a cart.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
              }
              else {
                data.cartData.delivery[di] = product;
                Swal.fire({
                  icon: 'success',
                  title: 'product update successfully.',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            });
        }
        else {
          if (data.cartData.pickup.length > 0) {
            data.cartData.pickup.forEach(
              (pp, pi) => {
                if (parseInt(pp.lpid) === parseInt(id)) {
                  data.cartData.pickup.splice(pi, 1);
                  data.cartData.delivery.push(product);
                  Swal.fire({
                    icon: 'success',
                    title: 'product update successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
                else {
                  data.cartData.delivery.push(product);
                  this.localCart.localCount += 1;
                  Swal.fire({
                    icon: 'success',
                    title: 'product added in a cart.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
              });
          }
          else {
            data.cartData.delivery.push(product);
            this.localCart.localCount += 1;
            Swal.fire({
              icon: 'success',
              title: 'product added in a cart.',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        break;
      default:
        break;
    }
  }
  public getLabel() {
    return this.productInfo.include_tav ? 'incl_tav' : 'excl_tav';
  }
  public htmlpopup() {
    $('.popouterbox').fadeOut(300, function () {
      $('.modal-backdrop').fadeOut(250, function () {
        $('body').removeClass('overflowhidden');
        $('.popouterbox .popup-block').removeAttr('style');
        $(this).remove();
      });
    });
  }

  public _getCartCount() {
    let user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    let info = {
      userId: user.id,
      token: user.token_key,
    }
    this.serviceCall._getCartCount(info).subscribe(data => {
      console.log(data);
    })
  }

  public selectedUnit(event) {
    this.unitData = this.productInfo.unitdata.find(unit => unit.id == event.target.value);
  }

}
