import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  errorStatus = true;
  errorMSG: string = "";
  cartData: any[] = [];
  constructor(
    private serviceCall: APIServiceService,
    private router: Router,
    private cookie: CookieService,
    private location: Location) { }

  ngOnInit() {
    if (this.cookie.check(window.location.hostname)) this.router.navigate(['home']);
  }

  /*
    * _userLogin call.
    * @param event:obj 
    * @return api response.
    * @author AD.
  */
  public login(e) {
    e.preventDefault();
    if (e.target.upass.value.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/)) {
      let info = {
        email: e.target.uemail.value,
        password: e.target.upass.value,
      }
      this.serviceCall._userLogin(info).subscribe(
        (res) => {
          let tempData: any = res;
          if (tempData.status === 1) {

            /* Error hide */
            this.errorStatus = true;
            this.errorMSG = "";

            /* user data store in cookie using hostname */
            let user = tempData.data;
            if (this.cookie.check(window.location.hostname + 'local')) {

              let local = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')));
              /* Local pickup */
              local.cartData.pickup.forEach(
                product => {
                  this.cartData.push({
                    product_id: product.lpid,
                    quantity: parseInt(product.product_packages),
                    tab_type: 1,
                    address_id: 0,
                    unity: product.unity
                  })
                }
              );

              /* Local delivery */
              local.cartData.delivery.forEach(
                product => {
                  this.cartData.push({
                    product_id: product.lpid,
                    quantity: parseInt(product.product_packages),
                    tab_type: 2,
                    address_id: 0,
                    unity: product.unity
                  })
                }
              );
              /* local cart sync with user cart */
              this.serviceCall._localcartSync({ userId: tempData.data.id, cartData: JSON.stringify(this.cartData), token: tempData.data.token_key }).subscribe(
                res => {
                  let tempData: any = res;
                  if (tempData.status == 1) {
                    user.cart_count = tempData.totalCartCount;
                    this.cartData = [];
                    this.cookie.delete(window.location.hostname + 'local');
                  }
                })
            }
            let info = {
              userId: user.id,
              token: user.token_key,
            }
            this.serviceCall._getCartCount(info).subscribe((data: any) => {
              console.log('cart count', data);
              this.serviceCall.cartCount = data.data.cart_count;
              let cookieData = JSON.parse(atob(this.cookie.get(window.location.hostname)));
              cookieData.cart_count = this.serviceCall.cartCount;
              this.cookie.set(window.location.hostname, btoa(JSON.stringify(cookieData)));
            })
            this.cookie.set(window.location.hostname, btoa(JSON.stringify(user)));
            this.router.navigate(['home'])
          }
          else {
            /* Error show */
            this.errorMSG = tempData.message;
            this.errorStatus = false;
          }
        })
    }
    else {
      /* Error show */
      this.errorMSG = "Password pattern not match";
      this.errorStatus = false;
      e.target.upass.focus();
    }
  }
}
