import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

declare let $;

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.css'],
})

export class SearchProductComponent implements OnInit {

  langId: string;
  urlData: string;
  suggestionList: any = [];

  /* page var */
  lastValue: number = 10;
  fetchData: any = [];
  shimmer: boolean = false;

  constructor(private serviceCall: APIServiceService, private cookie: CookieService, private router: Router, private ActivatedRoute: ActivatedRoute, private location: Location) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.ActivatedRoute.params.subscribe(params => this.urlData = params.token);
    this.langId = this.serviceCall._languagePass(sessionStorage.getItem('lan'));
  }

  ngAfterViewInit() { this.getData(); }

  /*
    * getting product by category || product-name by api.
    * @param  word, token-key:string 
    * @return api-response
    * @author Deepak Alram
  */
  public getData() {
    this.shimmer = false;

    let userId;

    if (this.cookie.check(window.location.hostname)) { userId = JSON.parse(atob(this.cookie.get(window.location.hostname))) }

    this.serviceCall._searchProductList({ word: this.urlData, lang_id: this.langId, user_id: userId ? userId.id : 0 }).subscribe(
      (res) => {
        let tempData: any = res;
        if (tempData.status == 1 && tempData.data.length != 0) { this.fetchData = tempData.data; this.shimmer = true; }
        else {
          this.shimmer = true;
          console.log(tempData.message + " || " + `Data Length will ${tempData.data.length}`);
        }
      });
  }

  /*
    * onScroll data array to next 10 products.
    * @param no-param
    * @return no-return
    * @author Deepak Alram
  */
  public onScroll() {
    this.lastValue = this.lastValue + 10;
  }

  /*
    * onScrollUp reset data array.
    * @param no-param
    * @return no-return
    * @author Deepak Alram
  */
  public onScrollUp() {
    this.lastValue = 10;
  }

  public getActualPrice(price, discount) {
    return ((parseFloat(price)) - (parseFloat(discount))).toFixed(2);
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }

  public getLabel(data) {
    return data ? 'incl_tav' : 'excl_tav';
  }
}
