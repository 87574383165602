import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { APIServiceService } from 'src/app/api/api.service';
declare let $;
@Component({
	selector: 'app-profile-page',
	templateUrl: './profile-page.component.html',
	styleUrls: ['./profile-page.component.css']
})
export class ProfilePageComponent implements OnInit {
	langId: string;
	iaddress: string;
	icheck: boolean;
	user: any = {};
	profile: any = {};
	daddress: any[] = [];
	selectCountry: string = 'BE';
	belgiumCitys: any[] = [];
	selectCityPostalCode: string;
	constructor(
		private api: APIServiceService,
		private cookie: CookieService) { }

	ngOnInit() {
		this.api.isMenuActive = true;
		this.user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
		this.langId = this.api._languagePass(sessionStorage.getItem('lan'));
		console.log(this.user);
		/* Popup function
  ---------------------------------------------------------------------*/
		var $dialogTrigger = $('.poptrigger'), $pagebody = $('body');
		$dialogTrigger.click(function () {
			var popID = $(this).attr('data-rel');
			$('body').addClass('overflowhidden');
			var winHeight = $(window).height();
			$('#' + popID).fadeIn();
			var popheight = $('#' + popID).find('.popup-block').outerHeight(true);
			if ($('.popup-block').length) {
				var popMargTop = popheight / 2;
				//var popMargLeft = ($('#' + popID).find('.popup-block').width()/2);
				if (winHeight > popheight) {
					$('#' + popID).find('.popup-block').css({
						'margin-top': -popMargTop,
						//'margin-left' : -popMargLeft
					});
				} else {
					$('#' + popID).find('.popup-block').css({
						'top': 0,
						//'margin-left' : -popMargLeft
					});
				}
			}
			$('#' + popID).append("<div class='modal-backdrop'></div>");
			$('.popouterbox .modal-backdrop').fadeTo("slow", 0.70);
			$('.custom-pop-box .modal-backdrop').fadeTo("fast", 0.88);
			if (popheight > winHeight) {
				$('.popouterbox .modal-backdrop').height(popheight);
			}
			$('#' + popID).focus();
			return false;
		});
		$(window).on("resize", function () {
			if ($('.popouterbox').length && $('.popouterbox').is(':visible')) {
				var popheighton = $('.popouterbox .popup-block').height() + 60;
				var winHeight = $(window).height();
				if (popheighton > winHeight) {
					$('.popouterbox .modal-backdrop').height(popheighton);
					$('.popouterbox .popup-block').removeAttr('style').addClass('taller');
				} else {
					$('.popouterbox .modal-backdrop').height('100%');
					$('.popouterbox .popup-block').removeClass('taller');
					$('.popouterbox .popup-block').css({
						'margin-top': -(popheighton / 2)
					});
				}
			}
		});
		//Close popup   
		$(document).on('click', '.close-dialogbox, .modal-backdrop', function () {
			$(this).parents('.popouterbox').fadeOut(300, function () {
				$(this).find('.modal-backdrop').fadeOut(250, function () {
					$('body').removeClass('overflowhidden');
					$('.popouterbox .popup-block').removeAttr('style');
					$(this).remove();
				});
			});
			return false;
		});
		$('.deleteaddress').click(function (e) {
			$(this).parent('li').remove();
			return false;
		});
		$('.fixlabelinput .form-group label').each(function () {
			var paddingWidth = $(this).width();
			$(this).next('input').css('padding-left', paddingWidth + 18 + 'px');
		});
		$('.fixlabelinput .form-group label').each(function () {
			var paddingWidth = $(this).width();
			$(this).next('select').css('padding-left', paddingWidth + 18 + 'px');
		})
		$('.fixlabelinput .form-group label').each(function () {
			var paddingWidth = $(this).width();
			$(this).next('textarea').css('padding-left', paddingWidth + 18 + 'px');
		});
		$('.passwordicon').click(function () {
			$(this).toggleClass('hide-password');
			if ($(this).hasClass('hide-password')) {
				$(this).prev('input').attr('type', 'text');
			} else {
				$(this).prev('input').attr('type', 'password');
			}
		});
		this.getUserDetails();
		this.getBelgiumCitys();
	}

	/*
		* _userViewProfile call.
		* @param info:obj. 
		* @return api-response
		* @author AD
	*/
	public getUserDetails() {
		this.api._userViewProfile({ userId: this.user.id, token: this.user.token_key }).subscribe(
			res => {
				let tempData: any = res;
				if (tempData.status == 1) {
					this.profile = tempData.data;
					/* this.imagePreview = this.profileData.profile_image; */
					this.daddress = this.profile.get_delivery_address;
					if (this.profile.invoice_street_number && this.profile.invoice_city && this.profile.invoice_country) {
						this.iaddress = this.objtoStringAddress([this.profile.invoice_street_number, this.profile.invoice_city, this.profile.invoice_country, this.profile.invoice_postal_code])
					}
					this.checkIDadrees();
				}
				else console.log(tempData.message);
			}, err => {
				if (err.status === 401) {
					this.api._conditionalLogout();
				}
			});
	}

	/*
		* _getBelgiumCitys call.
		* @param event:obj 
		* @return api response.
		* @author AD.
	*/
	public getBelgiumCitys() {
		this.api._getBelgiumCitys().subscribe(
			res => {
				let tempData: any = res;
				tempData.status === 1 ? this.belgiumCitys = tempData.data : console.log(tempData.message);
			})
	}

	/*
		* _addDeliveryAddress. call
		* @param info:obj.
		* @return api-response
		* @author AD
	*/
	public addIDaddress(e) {

		e.preventDefault();
		if (e.target.checked) {
			let info = {
				userId: this.user.id,
				delivery_street_address: this.profile.invoice_street_number,
				delivery_city: this.profile.invoice_city,
				delivery_country: this.profile.invoice_country,
				delivery_postal_code: this.profile.invoice_postal_code,
				lang_id: this.langId,
				token: this.user.token_key,
			}
			this.api._addDeliveryAddress(info).subscribe(
				res => {
					let tempData: any = res;
					tempData.status == 1 ? this.getUserDetails() : console.log(tempData.message);
				}, err => {
					if (err.status === 401) {
						this.api._conditionalLogout();
					}
				});
		}
	}

	/*
		* _setDefaultAddress call.
		* @param info:obj. 
		* @return api response.
		* @author AD.
	*/
	public setDefaultAddress(dataObj) {
		let info = {
			addressId: dataObj.id,
			isDefault: 1,
			userId: this.user.id,
			token: this.user.token_key,
		}
		this.api._setDefaultAddress(info).subscribe(
			res => {
				let tempData: any = res;
				tempData.status == 1 ? this.getUserDetails() : console.log(tempData.message);
			}, err => {
				if (err.status === 401) {
					this.api._conditionalLogout();
				}
			});
	}

	/*
		* _addDeliveryAddress( new address ) call.
		* @param info:obj.
		* @return api-response.
		* @author AD.
	*/
	public addNewAddress(e) {
		e.preventDefault();
		let info = {
			userId: this.user.id,
			delivery_street_address: e.target.adstreetnr.value,
			delivery_city: e.target.adcity.value,
			delivery_country: e.target.adcountry.value,
			delivery_postal_code: e.target.adpostal.value,
			lang_id: this.langId,
			token: this.user.token_key,
		}
		this.api._addDeliveryAddress(info).subscribe(
			res => {
				let tempData: any = res;
				if (tempData.status == 1) {
					this.getUserDetails();
					$('.close-dialogbox, .modal-backdrop').parents('.popouterbox').fadeOut(300, function () {
						$(this).find('.modal-backdrop').fadeOut(250, function () {
							$('body').removeClass('overflowhidden');
							$('.popouterbox .popup-block').removeAttr('style');
							$(this).remove();
						});
					});
				}
				else console.log(tempData.message);
			},
			err => {
				if (err.status === 401) {
					this.api._conditionalLogout();
				}
			});
	}

	/* html array data to string return */
	public objtoStringAddress(address: any[]) {
		return address.join();
	}

	/* check invoice address is delivery address or not */
	public checkIDadrees() {
		let A = this.iaddress;
		this.daddress.some(address => {
			let B = this.objtoStringAddress([address.delivery_street_number, address.delivery_city, address.delivery_country, address.delivery_postal_code]);
			if (B === A) this.icheck = true;
		});
	}

	/*
		* user update profile img show live.
		* @param no-param 
		* @return no-return
		* @author AD
		* not use.
	*/
	public priview(file) {
		let reader = new FileReader();
		reader.onload = (_event) => {
			$('#imagePreview').css('background-image', 'url(' + reader.result + ')');
			$('#imagePreview').hide();
			$('#imagePreview').fadeIn(400);
			//this.imagePreview = reader.result;
		}
		reader.readAsDataURL(file[0]);
	}

	/*
		* _updateProfile call.
		* @param userrole-id, name, phone-no, usre-id, profile-img, lang-type, token-key: string 
		* @return api response.
		* @author AD.
		* not use now.
	*/
	public editProfile(e, b, c) {
		e.preventDefault();
		this.api._updateProfile(this.user.get_users_role.role_id, b.value, c.value, this.user.id, '', this.langId, this.user.token_key).subscribe(
			res => {
				let tempData: any = res;
				tempData.status == 1 ? this.getUserDetails() : console.log(tempData.message);
			}, err => {
				if (err.status === 401) {
					this.api._conditionalLogout();
				}
			});
	}

	/*
		* delete delivery address by api.
		* @param user-id, address-id: string. 
		* @return api response.
		* @author AD.
		* not use now.
	*/
	public deleteDeliveryAddress(a, b) {
		console.log(a, b);
	}

	/*
		* get postal code for select city in html.
		* @param event:obj 
		* @return api response.
		* @author AD.
	*/
	public getPostalCode(e) {
		this.selectCityPostalCode = $('#belgiumCitysList [value="' + e.target.value + '"]').data('postalcode');
	}
}
