import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import PerfectScrollbar from 'perfect-scrollbar';
import { CookieService } from 'ngx-cookie-service';
import { APIServiceService } from 'src/app/api/api.service';


declare let $;

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css']
})
export class OrderPageComponent implements OnInit {

  user: any = {};
  langId: string;
  orders: any[] = [];
  loaderActive: boolean;

  constructor(private Api: APIServiceService, private cookie: CookieService, private location: Location) { }

  ngOnInit() {
    this.langId = this.Api._languagePass(sessionStorage.getItem('lan'));
    this.user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    this.getUserOrders();
  }

  ngAfterViewInit() {

    setTimeout(() => {

      const ps = new PerfectScrollbar('.myorderaereainn', {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20
      });

      ps.update();

      $(window).on("resize", function () {
        ps.update();
      });

    }, 3500);

  }

  public getUserOrders() {
    this.loaderActive = false;
    let info = {
      userId: this.user.id,
      token: this.user.token_key,
    }
    this.Api._userOrderList(info).subscribe(
      (res) => {
        let tempData: any = res;
        tempData.status === 1 ? this.orders = tempData.data : console.log(tempData.message);

        setTimeout(() => {
          this.loaderActive = true;
        }, 1000);
      },
      (err) => {
        if (err.status === 401) {
          this.Api._conditionalLogout();
        }
      })
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }
}
