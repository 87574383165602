import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router, ActivatedRoute } from '@angular/router';
import { APIServiceService } from 'src/app/api/api.service';

import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';

declare let $;

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent implements OnInit {


  orderId: string;
  user: any = {};
  pproducts: any[] = [];
  dproducts: any[] = [];
  total: any = {};
  loaderActive: boolean;

  constructor(private Api: APIServiceService, private router: Router, private ActivatedRoute: ActivatedRoute, private cookie: CookieService, private location: Location) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    this.ActivatedRoute.params.subscribe(params => (this.orderId = params.token));
    this.getOrderDetails();
  }

  ngAfterViewInit() {
    setTimeout(() => {

      const pp = new PerfectScrollbar('.orderproductdetail', {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20
      });

      const dp = new PerfectScrollbar('.orderproductdetailsroll', {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20
      });

      $(window).on("resize", function () {
        pp.update();
        dp.update();
      });

    }, 3500);
  }

  public getOrderDetails() {
    this.loaderActive = false;
    this.Api._getOrderDetails({ userId: this.user.id, orderId: this.orderId, token: this.user.token_key }).subscribe(
      (res) => {
        let tempData: any = res;
        if (tempData.status === 1) {

          this.pproducts = tempData.data.pickupData.pickup
          this.dproducts = tempData.data.deliveryData.delivery

          this.total.ptotal = parseFloat(tempData.data.pickupData.pickupSubTotal).toFixed(0);
          this.total.dtotal = parseFloat(tempData.data.deliveryData.deliverySubTotal).toFixed(2);

        }
        else console.log(tempData.message);

        setTimeout(() => {
          this.loaderActive = true;
        }, 1000);

      },
      err => {
        if (err.status === 401) {
          this.Api._conditionalLogout();
        }
      })
  }

  /* covert float to int */
  public setAmount(data) {
    return Math.round(parseFloat(data));
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }

}
