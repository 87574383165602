import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//Components
import { RegistrationPageComponent } from './components/registration/registration-page.component';
import { LoginPageComponent } from './components/login/login-page.component';
import { HomePageComponent } from './components/home/home-page.component';
import { SubCategoryComponent } from './components/subCategory/sub-category.component';
import { CategoryPageComponent } from './components/category/category-page.component';
import { ProductViewComponent } from './components/productDetails/product-view.component';
import { ProfilePageComponent } from './components/userProfile/profile-page.component';
import { ForgotPassPageComponent } from './components/forgetPassword/forgot-pass-page.component';
import { ResetpassPageComponent } from './components/resetPassword/resetpass-page.component';
import { ChangePassComponent } from './components/changePassword/change-pass.component';
import { CartDeliveryComponent } from './components/cartDelivery/cart-delivery.component';
import { OrderPageComponent } from './components/order/order-page.component';
import { ViewOrderComponent } from './components/view-order/view-order.component';
import { SearchProductComponent } from './components/search/search-product.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { PageNotFoundPageComponent } from './components/pageNotFound/page-not-found-page.component';
import { RouteAuthenticationGuard } from './guard/route-authentication.guard'
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'registration', component: RegistrationPageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'forgot', component: ForgotPassPageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'sub_category/:token', component: SubCategoryComponent },
  { path: 'products/:token', component: CategoryPageComponent },
  { path: 'Product-view/:token', component: ProductViewComponent },
  { path: 'profile', component: ProfilePageComponent, canActivate: [RouteAuthenticationGuard] },
  { path: 'reset/:token', component: ResetpassPageComponent },
  { path: 'change_password', component: ChangePassComponent, canActivate: [RouteAuthenticationGuard] },
  { path: 'myorders', component: OrderPageComponent, canActivate: [RouteAuthenticationGuard] },
  { path: 'cart_delivery', component: CartDeliveryComponent },
  { path: 'vieworder/:token', component: ViewOrderComponent, canActivate: [RouteAuthenticationGuard] },
  { path: 'Search-product/:token', component: SearchProductComponent },
  { path: 'order_success/:id', component: OrderSuccessComponent, canActivate: [RouteAuthenticationGuard] },
  { path: 'order_confirm/:id', component: OrderSuccessComponent, canActivate: [RouteAuthenticationGuard] },
  { path: 'select-address', component: DeliveryAddressComponent },
  { path: '**', component: PageNotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
