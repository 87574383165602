import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { APIServiceService } from 'src/app/api/api.service';
import { Router } from "@angular/router";
import PerfectScrollbar from 'perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
declare let $;
@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"]
})

export class HomePageComponent implements OnInit {
  @ViewChild('brandfilter', { static: false }) brandfilter: ElementRef;
  allCategory: any = [];
  suggestionList: any = [];
  lagType: any;
  brands: any = [];
  brandsFilter: any[] = [];
  constructor(
    private serviceCall: APIServiceService,
    private router: Router,
    private translate: TranslateService, ) { }

  ngOnInit() {
    this.serviceCall.isMenuActive = true;
    this.lagType = this.serviceCall._languagePass(sessionStorage.getItem("lan"));
    /*  document.querySelector("body").setAttribute("class", "homemain"); */
    document.querySelector("body").setAttribute("class", "homepage");
    this.getCategorys(this.lagType, undefined);
    this.getBrands();
  }
  ngAfterViewInit() {
    /* productlistfilterboxinner */
    var $headerHight = $("#header").height();
    $(".mainhomecat").css("padding-top", $headerHight + "px");
    $('.searchbarheader .searchicon').click(function () {
      $(this).parents('.searchbox').toggleClass('active');
    });
    $('.searchfilterbtn').click(function () {
      $('body').toggleClass('filtershow');
    });
    $('.filtercloseapplybtn a').click(function () {
      $('body').removeClass('filtershow');
    });
    const ps = new PerfectScrollbar(this.brandfilter.nativeElement);
    ps.update();
    $(window).on("resize", function () {
      ps.update();
    }).resize();

  }

  ngOnDestroy() {
    /*  document.querySelector("body").removeAttribute("class"); */
    /* filter obj store in session storage */
    sessionStorage.setItem('filter', btoa(JSON.stringify({ brands: this.brandsFilter.join() })));
  }

  /*
    * _gettingCategory call.
    * @param info : obj.
    * @return api response
    * @author AD.
  */
  public getCategorys(lang, brands) {
    let info = {
      langId: lang,
      brands: brands || '',
    }
    this.serviceCall._gettingCategory(info).subscribe(res => {
      let tempData: any = res;
      tempData.status == 1 ? this.allCategory = tempData.data : console.log(tempData.message);
    });
  }

  /*
    * _getAllBrands call.
    * @param no-param.
    * @return no-return.
    * @author AD
  */
  public getBrands() {
    this.serviceCall._getAllBrands().subscribe(
      res => {
        let tempData: any = res;
        tempData.status === 1 ? this.brands = tempData.data : console.log(tempData.message);
      })
  }

  /*
    * get brand Filter value.
    * @param no-param.
    * @return no-return.
    * @author AD
  */
  public getFiltervalue(e) {
    if (e.target.checked) {
      this.brandsFilter.push(e.target.dataset.brandid);
      this.getCategorys(this.lagType, this.brandsFilter.join());
    }
    else {
      this.brandsFilter.splice(this.brandsFilter.indexOf(e.target.dataset.brandid), 1);
      this.getCategorys(this.lagType, this.brandsFilter.join());
    }
  }

}
