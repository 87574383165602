import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { APIServiceService } from 'src/app/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.css']
})
export class OrderSuccessComponent implements OnInit {
  userId: any;
  orderId: any;
  user: any = {};
  orderDetails: any;
  totalAmount: any;
  status: any;
  isOrderConfirm: boolean = false;
  isOrderSuccess: boolean = false;
  deliveryDate: any;
  langId: string;
  selectedDeliveryDate: any;
  loaderActive: boolean;
  constructor(
    private Api: APIServiceService,
    private cookie: CookieService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.orderId = params.id;
    });
  }

  ngOnInit() {
    this.langId = this.Api._languagePass(sessionStorage.getItem('lan'));
    const path = this.router.url.split('/')[1];
    if (path === 'order_confirm') {
      this.isOrderConfirm = true;
    }
    if (path === 'order_success') {
      this.isOrderSuccess = true;
    }
    console.log('route', this.router.url.split('/')[1]);
    this.Api.deliveryDate.subscribe((data) => {
      console.log('deliveryDate', data)
      if (data) {
        this.deliveryDate = data;
        console.log('deliveryDate', this.deliveryDate);
        this.selectedDeliveryDate = data[0];
      }
    })
    this.cartCount();
    this.getOrderSuccessDetails();
  }

  /* back to previous page */
  public routeToMyOrders(): void {
    this.router.navigate(['/myorders']);
  }

  /* reset cart Count */
  public cartCount() {
    this.user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    // this.user.cart_count = 0;
    this.cookie.set(window.location.hostname, btoa(JSON.stringify(this.user)));
  }

  /* Get Order Success Details*/
  getOrderSuccessDetails() {
    let info = {
      userId: this.user.id,
      orderId: this.orderId,
      lang_id: '1',
      token: this.user.token_key,
    }
    this.loaderActive = false;
    this.Api._viewConfirmOrderDetail(info).subscribe((data: any) => {
      this.status = data.status;
      this.orderDetails = data.data;
      this.loaderActive = true;
      this.totalAmount = this.getFloatData(this.orderDetails.total_amount) + this.getFloatData(this.orderDetails.tav_amount) + this.getFloatData(this.orderDetails.delivery_charges);
    })
  }

  /* covert float to int */
  public getFloatData(data) {
    return parseFloat(data);
  }

  /* covert float to int */
  public getFloatValue(data) {
    return parseFloat(data).toFixed(2);
  }
  /** Route to Mollie Screen */
  routeToPayment() {
    this.Api.cartCount = 0;
    this.user.cart_count = 0;
    this.cookie.set(window.location.hostname, btoa(JSON.stringify(this.user)));
    let info = {
      userId: this.user.id,
      order_id: this.orderId,
      lang_id: this.langId,
      token: this.user.token_key,
      device_type: '3',
      delivery_date: ''
    }
    this.Api._checkoutOrder(info).subscribe(
      (res) => {
        let tempData: any = res;
        if (tempData.status === 1) {
          switch (tempData.data.isMollie) {
            case "1":
              window.location.replace(tempData.data.link)
              break;
            case "0":
              console.log("order successfully");
              this.router.navigate(['myorders']);
              break;
            default:
              break;
          }
        }
        else console.log("checkoutOrder", tempData.message);
      }, err => {
        if (err.status === 401) {
          this.Api._conditionalLogout();
        }
      })
  }

  selectDeliveryDate(event) {
    console.log('change date', event);
    this.selectedDeliveryDate = event.target.value;
  }

  routeToAddressScreen() {
    this.router.navigate(['/select-address']);
  }


}
