import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';
import { TranslateService } from '@ngx-translate/core';

declare let $;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  categorys: any;

  constructor(private serviceCall: APIServiceService, private translate: TranslateService) { }

  ngOnInit() { this.getCategorys(); }

  /*
    * _gettingCategory call.
    * @param info : obj.
    * @return api response
    * @author AD.
  */
  public getCategorys() {

    let info = {
      langId: this.serviceCall._languagePass(sessionStorage.getItem('lan')),
      brands: '',
    }
    this.serviceCall._gettingCategory(info).subscribe(res => {
      let tempData: any = res;
      tempData.status == 1 ? this.categorys = tempData.data : console.log(tempData.message);
    });
  }

}
