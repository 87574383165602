import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private router: Router) {
    let templan = sessionStorage.getItem("lan");
    if (templan == null || templan == "") {
      translate.use("nl");
      sessionStorage.setItem("lan", "nl");
    } else {
      translate.use(templan);
    }
  }

  ngOnInit() { }

}
