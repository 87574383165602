import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { APIServiceService } from 'src/app/api/api.service';
import { Router, ActivatedRoute } from "@angular/router";
import PerfectScrollbar from "perfect-scrollbar";
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

declare let $;

@Component({
  selector: "app-category-page",
  templateUrl: "./category-page.component.html",
  styleUrls: ["./category-page.component.css"]
})

export class CategoryPageComponent implements OnInit {

  @ViewChild('brandfilter', { static: false }) brandfilter: ElementRef;

  categoryId: string;
  langId: string;
  customFilter: any = {};
  products: any = [];
  subCategorys: any = [];
  suggestionList: any = [];
  brands: any[] = [];

  /* page var */
  categoryName: string;
  pageCount: number
  apiFilter: any = {};
  colorLength: number;
  formatLength: number;
  lastValie: number = 10;

  shimmer: boolean;

  Breadcrumb: any[] = [];


  constructor(private cookie: CookieService, private serviceCall: APIServiceService, private router: Router, private ActivatedRoute: ActivatedRoute, private location: Location) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {

    this.ActivatedRoute.params.subscribe(params => (this.categoryId = params.token));
    this.customFilter = { brands: [], price: [0, 0], format: [], color: [] };
    this.langId = this.serviceCall._languagePass(sessionStorage.getItem("lan"));

    if (sessionStorage.getItem('filter')) {
      this.customFilter.brands = JSON.parse(atob(sessionStorage.getItem('filter'))).brands.split(',');
    }

    this.serviceCall.isMenuActive = true;

    if (sessionStorage.getItem('breadcrumb')) this.Breadcrumb = JSON.parse(atob(sessionStorage.getItem('breadcrumb')));

    this.gettingCategory();

    this.getCategoryWiseProduct(this.categoryId, undefined, this.langId, undefined, undefined, undefined, undefined);
  }

  ngAfterViewInit() {

    $('.searchfilterbtn').click(function () {
      $('body').toggleClass('filtershow');
    });

    $('.filtercloseapplybtn a').click(function () {
      $('body').removeClass('filtershow');
    });

    setTimeout(() => {

      /* let self = this
      $("#slider-range").slider({
          range: true,
          min: self.apiFilter.min,
          max: self.apiFilter.max,
          values: [0, 700],
          slide: function (event, ui) {
            self.customFilter.price = ui.values;
          },
          stop: function (event, ui) {
            $("#amount").val("€" + ui.values[0] + " to €" + ui.values[1]);
            self.customFilter.price = ui.values;
            self.getCategoryWiseProduct(self.categoryId, undefined, self.langId, self.customFilter.price[0], self.customFilter.price[1], self.customFilter.format.join(), self.customFilter.color.join());
          }
      }); */

      if ($('.subcategorylisting').length > 0) {
        const ps = new PerfectScrollbar(".subcategorylisting", {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 20
        });

        $(window).on("resize", function () { ps.update() }).resize();
      }

      if ($(".productlistfilterboxinner.brand").length > 0) {
        const bps = new PerfectScrollbar(".productlistfilterboxinner.brand", {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 20
        });

        $(window).on("resize", function () { bps.update() }).resize();
      }

      if ($(".productlistfilterboxinner.color").length > 0) {
        const cps = new PerfectScrollbar(".productlistfilterboxinner.color", {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 20
        });

        $(window).on("resize", function () { cps.update() }).resize();
      }

      if ($(".productlistfilterboxinner.format").length > 0) {
        const fps = new PerfectScrollbar(".productlistfilterboxinner.format", {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 20
        });

        $(window).on("resize", function () { fps.update() }).resize();
      }

    }, 2000);

  }

  /*
    * _getProductByCategory call.
    * @param categoryId, pageNo || 1, langtype, minval || "", maxval || "", format || "", color || "", this.brandIds :string.
    * @return no-return.
    * @author AD
  */
  public getCategoryWiseProduct(categoryId, pageNo, langtype, minval, maxval, format, color) {

    this.shimmer = false;

    let userId;

    if (this.cookie.check(window.location.hostname)) { userId = JSON.parse(atob(this.cookie.get(window.location.hostname))) }

    let info = {
      category_id: categoryId,
      page_number: pageNo || 1,
      lang_id: langtype,
      min_price: minval || 0,
      max_price: maxval || "",
      nominal_format: format || "",
      color: color || "",
      brand: this.customFilter.brands.join() || "",
      user_id: userId ? userId.id : 0
    }

    this.serviceCall._getProductByCategory(info).subscribe(
      (res) => {
        let tempData: any = res;
        if (tempData.status == 1 && tempData.data.product.length != 0) {
          this.products = tempData.data.product;
          this.apiFilter = tempData.data.filters;
          this.colorLength = this.apiFilter.color.length;
          this.formatLength = this.apiFilter.nominal_format.length;
          this.shimmer = true;
        }
        else {
          this.products = [];
          this.shimmer = true;
          if ($('.productlistfilter').length === 0) $('body').find('.productlistingsection').toggleClass('fullproductlisting');
          console.log("Data length is 0 || call status !1");
        }
      });
  }

  /*
    * _gettingCategory call.
    * @param info:obj.
    * @return no-return.
    * @author AD
  */
  public gettingCategory() {
    let info = {
      langId: this.langId,
      brands: this.customFilter.brands.join() || '',
    }
    this.serviceCall._gettingCategory(info).subscribe(res => {
      let tempData: any = res;
      if (tempData.status == 1) {
        this.getNlevelSubCategroy(tempData.data, parseInt(this.categoryId), 'sub_category');
      }
    });
  }

  /*
    * filter in products.
    * @param no-param.
    * @return no-return.
    * @author AD.
  */
  public getFiltervalue(e) {
    if (e.target.checked) {
      if (e.target.dataset.formatname) {
        this.customFilter.format.push(e.target.dataset.formatname);
        this.getCategoryWiseProduct(this.categoryId, undefined, this.langId, 0, 0, this.customFilter.format.join(), this.customFilter.color.join());
      }
      else if (e.target.dataset.colorname) {
        this.customFilter.color.push(e.target.dataset.colorname);
        this.getCategoryWiseProduct(this.categoryId, undefined, this.langId, 0, 0, this.customFilter.format.join(), this.customFilter.color.join());
      }
      else if (e.target.dataset.brandid) {
        this.customFilter.brands.push(e.target.dataset.brandid);
        sessionStorage.setItem('filter', btoa(JSON.stringify({ brands: this.customFilter.brands.join() })));
        this.getCategoryWiseProduct(this.categoryId, undefined, this.langId, 0, 0, this.customFilter.format.join(), this.customFilter.color.join());
      };
    }
    else {
      if (e.target.dataset.formatname) {
        this.customFilter.format.splice(this.customFilter.format.indexOf(e.target.dataset.formatname), 1);
        this.getCategoryWiseProduct(this.categoryId, undefined, this.langId, 0, 0, this.customFilter.format, this.customFilter.color);
      }
      else if (e.target.dataset.colorname) {
        this.customFilter.color.splice(this.customFilter.color.indexOf(e.target.dataset.colorname), 1);
        this.getCategoryWiseProduct(this.categoryId, undefined, this.langId, 0, 0, this.customFilter.format, this.customFilter.color);
      }
      else if (e.target.dataset.brandid) {
        this.customFilter.brands.splice(this.customFilter.brands.indexOf(e.target.dataset.brandid), 1);
        sessionStorage.setItem('filter', btoa(JSON.stringify({ brands: this.customFilter.brands.join() })));
        this.getCategoryWiseProduct(this.categoryId, undefined, this.langId, 0, 0, this.customFilter.format.join(), this.customFilter.color.join());
      }
    }
  }

  /*
    * reset filter object.
    * @param no-param
    * @return no-return
    * @author Deepak Alram
  */
  public resetFilter() {
    this.customFilter = { brands: [], price: [null, null], format: [], color: [] };
  }

  /*
    * onScroll data array to next 10 products.
    * @param no-param
    * @return no-return
    * @author Deepak Alram
  */
  public onScroll() {
    this.lastValie = this.lastValie + 10;
  }

  /*
    * onScrollUp reset data array.
    * @param no-param
    * @return no-return
    * @author Deepak Alram
  */
  public onScrollUp() {
    this.lastValie = 10;
  }

  /*
    * _gettingWordByCategory call.
    * @param word: string.
    * @return api response.
    * @author AD.
  */
  public categoryWords(e) {

    e.preventDefault();

    this.serviceCall._gettingWordByCategory(e.target.value).subscribe(res => {
      let tempData: any = res;
      if (tempData.status == 1) {
        if (tempData.data.length > 0) this.suggestionList = tempData.data;
      } else console.log(tempData.message);
    });
  }

  /*
    * redirect to search-product page with data pass in url.
    * @param no-param.
    * @return no-return
    * @author AD
  */
  public toCategoryPage(e) {
    e.preventDefault();
    if (e.target.smsearch.value != "") {
      this.router.navigate(["Search-product", e.target.smsearch.value]);
      e.target.reset();
    }
  }

  /* selected value checked after reinitialize filter */
  public setChecked(type, compareValue) {
    switch (type) {
      case 0:
        return this.customFilter.format.some(format => format == compareValue);
      case 1:
        return this.customFilter.color.some(color => color == compareValue);
      case 3:
        return this.customFilter.brands.some(brand => brand == compareValue);

      default:
        console.log("not selected default value");
        break;
    }
  }

  /* n-level category find */
  public getNlevelSubCategroy(arr, itemId, nestingKey) {
    let parent = arr;
    arr.reduce((a, item) => {
      if (a) return a;
      if (item.id === itemId) { this.subCategorys = parent; this.brands = item.brands; this.categoryName = item.category_name; return false; }
      if (item[nestingKey]) return this.getNlevelSubCategroy(item[nestingKey], itemId, nestingKey)
    }, null);
  }

  /* covert float to int */
  public getActualPrice(price, discount) {
    return (parseFloat(price) - parseFloat(discount)).toFixed(2);
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }

  public getLabel(data) {
    return data ? 'incl_tav' : 'excl_tav';
  }

}
