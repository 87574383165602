import { Component, OnInit, ViewChild } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
declare let $;
@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.css']
})
export class DeliveryAddressComponent implements OnInit {
  @ViewChild('addressForm', { static: false }) addressForm;
  user: any;
  address: any;
  invoiceAddress: any;
  langId: string;
  profile: any = {};
  daddress: any[] = [];
  selectCountry: string = 'BE';
  belgiumCitys: any[] = [];
  selectCityPostalCode: string;
  iaddress: string;
  icheck: boolean;
  isPickup: boolean = false;
  isDelivery: boolean = false;
  pickupDate: any;
  deliveryDate: any;
  pickupProducts: any;
  deliveryProducts: any;
  orderId: any;
  currentSelectedPickupAddress: any;
  currentSelectedDeliveryAddress = '99999';
  loaderActive: boolean = false;
  constructor(
    public API: APIServiceService,
    private cookie: CookieService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
    this.getAdddress();
    this.langId = this.API._languagePass(sessionStorage.getItem('lan'));
    this.getUserDetails();
    this.getBelgiumCitys();
    this.getCartProducts();
  }

  getAdddress() {
    let info = {
      lang_id: this.API._languagePass(sessionStorage.getItem('lan')),
      userId: this.user.id,
      token: this.user.token_key,
    }
    this.loaderActive = false;
    this.API._getAddress(info).subscribe((address: any) => {
      this.address = address;
      // const pickupAddress = address.pickup_address.map((add: any, i: number) => {
      //   return { ...add, isChecked: i === 0 ? true : false }
      // });
      // this.address.pickup_address = pickupAddress;
      this.loaderActive = true;
      this.currentSelectedPickupAddress = address.pickup_address[0].id;
      this.invoiceAddress = address.invoice_address[0];
    })
  }
  /*
  * _addDeliveryAddress( new address ) call.
  * @param info:obj.
  * @return api-response.
*/
  public addNewAddress(e) {
    e.preventDefault();
    let info = {
      userId: this.user.id,
      delivery_street_address: e.target.adstreetnr.value,
      delivery_city: e.target.adcity.value,
      delivery_country: e.target.adcountry.value,
      delivery_postal_code: e.target.adpostal.value,
      lang_id: this.langId,
      token: this.user.token_key,
    }
    this.loaderActive = false;
    this.API._addDeliveryAddress(info).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status == 1) {
          this.loaderActive = true;
          this.getUserDetails();
          this.getAdddress();
          this.currentSelectedDeliveryAddress = tempData.data.address_id;
        }
        else console.log(tempData.message);
        console.log('address form', this.addressForm);
        this.addressForm.nativeElement.reset();
      },
      err => {
        if (err.status === 401) {
          this.API._conditionalLogout();
        }
      });
  }

  /* html array data to string return */
  public objtoStringAddress(address: any[]) {
    return address.join();
  }
  /*
		* _userViewProfile call.
		* @param info:obj. 
		* @return api-response
	*/
  public getUserDetails() {
    this.API._userViewProfile({ userId: this.user.id, token: this.user.token_key }).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status == 1) {
          this.profile = tempData.data;
          /* this.imagePreview = this.profileData.profile_image; */
          this.daddress = this.profile.get_delivery_address;
          if (this.profile.invoice_street_number && this.profile.invoice_city && this.profile.invoice_country) {
            this.iaddress = this.objtoStringAddress([this.profile.invoice_street_number, this.profile.invoice_city, this.profile.invoice_country, this.profile.invoice_postal_code])
          }
          this.checkIDadrees();
        }
        else console.log(tempData.message);
      }, err => {
        if (err.status === 401) {
          this.API._conditionalLogout();
        }
      });
  }
  /* check invoice address is delivery address or not */
  public checkIDadrees() {
    let A = this.iaddress;
    this.daddress.some(address => {
      let B = this.objtoStringAddress([address.delivery_street_number, address.delivery_city, address.delivery_country, address.delivery_postal_code]);
      if (B === A) this.icheck = true;
    });
  }
  /*
		* get postal code for select city in html.
		* @param event:obj 
		* @return api response.
	*/
  public getPostalCode(e) {
    this.selectCityPostalCode = $('#belgiumCitysList [value="' + e.target.value + '"]').data('postalcode');
  }
  /*
		* _getBelgiumCitys call.
		* @param event:obj 
		* @return api response.
	*/
  public getBelgiumCitys() {
    this.API._getBelgiumCitys().subscribe(
      res => {
        let tempData: any = res;
        tempData.status === 1 ? this.belgiumCitys = tempData.data : console.log(tempData.message);
      })
  }
  public getCartProducts() {
    if (this.cookie.check(window.location.hostname)) {
      let info = {
        userId: this.user.id,
        langId: this.langId,
        token: this.user.token_key,
      }
      this.API._getCartProducts(info).subscribe((cartDetails: any) => {
        if (cartDetails.status === 1) {
          console.log("cartDetails", cartDetails);
          if (cartDetails.data.pickup_data_item.pickup.length > 0) {
            this.isPickup = true;
            console.log('isPickup', this.isPickup)
          }
          if (cartDetails.data.delivery_data_item.delivery.length > 0) {
            this.isDelivery = true;
            console.log('isDelivery', this.isDelivery);
          }
        }
      },
        err => { if (err.status === 401) { this.API._conditionalLogout(); } })
    }
  }

  routeToOrderConfirm() {
    console.log('delivery', this.currentSelectedDeliveryAddress);
    console.log('pickup', this.currentSelectedPickupAddress);
    if (this.isPickup && !this.isDelivery) {
      if (this.currentSelectedPickupAddress === '')
        return false;
    }
    if (this.isDelivery && !this.isPickup) {
      if (this.currentSelectedDeliveryAddress === '') {
        return false;
      }
    }
    if (this.isPickup && this.isDelivery) {
      if (this.currentSelectedDeliveryAddress === '' && this.currentSelectedPickupAddress === '') {
        return false;
      }
    }
    let info = {
      userId: this.user.id,
      isPickup: this.isPickup ? 1 : 0,
      isDeliver: this.isDelivery ? 1 : 0,
      pickup_id: this.isPickup ? this.currentSelectedPickupAddress : 0,
      deliver_id: this.isDelivery ? this.currentSelectedDeliveryAddress : 0,
      token: this.user.token_key,
    }
    this.API._getOrderDate(info).subscribe(
      (res) => {
        let tempData: any = res;
        if (tempData.status === 1) {
          /* For single delivery date available */
          if (tempData.data.delivery_date.length == 0) {
            /* set set date for both */
            this.pickupDate = tempData.data.pickup_date;
            this.deliveryDate = tempData.data.delivery_date;
            this.API.deliveryDate.next(this.deliveryDate);
            let info = {
              userId: this.user.id,
              pickup_date: tempData.data.pickup_date,
              delivery_date: '',
              token: this.user.token_key,
            }
            /* create order api call */
            this.createOrderAPIMOdel(info);

          } /* For multiple delivery date available */
          else {
            /* set date for pickup */
            this.pickupDate = tempData.data.pickup_date;
            this.deliveryDate = tempData.data.delivery_date;
            let info = {
              userId: this.user.id,
              pickup_date: tempData.data.pickup_date,
              delivery_date: this.deliveryDate[0],
              token: this.user.token_key,
            }
            this.API.deliveryDate.next(this.deliveryDate);
            this.createOrderAPIMOdel(info);
          }

        }
        else console.log(tempData.message);
      },
      err => {
        if (err.status === 401) {
          this.API._conditionalLogout();
        }
      })
  }
  /*
   * _createOrder call.
   * @param info:obj.
   * @return api response.
 */
  public createOrderAPIMOdel(obj) {
    this.API._createOrder(obj).subscribe(
      (res) => {
        let tempData: any = res;
        console.log('tempData.message', tempData);
        if (tempData.status === 1) {
          this.orderId = tempData.data.order_id;
          this.router.navigate([`order_confirm/${this.orderId}`])
          // let info = {
          //   userId: this.user.id,
          //   order_id: tempData.data.order_id,
          //   lang_id: this.langId,
          //   token: this.user.token_key,
          //   device_type: '3'
          // }
        }
        else console.log("_createOrder", tempData.message);
      },
      err => {
        if (err.status === 401) {
          this.API._conditionalLogout();
        }
      })
  }
  changeSelectedAddress(event) {
    console.log('changeSelectedAddress', event);
    this.currentSelectedPickupAddress = event.target.value;
  }
  changeDeliveryAddress(event) {
    console.log("delibvery", event);
    this.currentSelectedDeliveryAddress = event.target.value;
  }
}
