import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { APIServiceService } from 'src/app/api/api.service';
import Swal from 'sweetalert2'
declare let $;
@Component({
  selector: 'app-cart-delivery',
  templateUrl: './cart-delivery.component.html',
  styleUrls: ['./cart-delivery.component.css']
})

export class CartDeliveryComponent implements OnInit {
  langId: string;
  belgiumCitys: any[] = [];
  user: any = {};
  cartObj: any;
  loaderActive: boolean;
  errorMSG: string = '';
  defaultDAddress: string = 'No Default Address Available';
  postalCode: string = '';
  selectCountry: string = 'BE';
  total: any = {};
  errorStatus: boolean = true;
  deliveryDateShow: boolean = false;
  pAddressList: any[] = [];
  dAddressList: any[] = [];
  pproducts: any[] = [];
  dproducts: any[] = [];
  sproducts: any[] = [];
  /* Date value */
  dDate: any;
  pDate: string;
  ddateList: any[] = [];
  localCartData: boolean = false;
  lptprice: number;
  localTotal: any = { lp: 0, ld: 0, lsubt: 0, lgrandt: 0 };

  constructor(
    private api: APIServiceService,
    private cookie: CookieService,
    private router: Router,
    private location: Location) { }

  ngOnInit() {
    this.api.isMenuActive = true;
    this.langId = this.api._languagePass(sessionStorage.getItem('lan'));
    /* get cart products call */
    this.getCartProducts();
    /* get belgium citys call */
    this.getBelgiumCitys();
    $(window).on("resize", function () {
      if ($('.popouterbox').length && $('.popouterbox').is(':visible')) {
        var popheighton = $('.popouterbox .popup-block').height() + 60;
        var winHeight = $(window).height();
        if (popheighton > winHeight) {
          $('.popouterbox .modal-backdrop').height(popheighton);
          $('.popouterbox .popup-block').removeAttr('style').addClass('taller');

        } else {
          $('.popouterbox .modal-backdrop').height('100%');
          $('.popouterbox .popup-block').removeClass('taller');
          $('.popouterbox .popup-block').css({
            'margin-top': -(popheighton / 2)
          });
        }
      }
    });

    $(document).on('click', '.close-dialogbox, .modal-backdrop', function () {
      if ($(this).parents('.popouterbox').attr('id') == 'deliveryDatePopup') {
        return true;
      }
      $(this).parents('.popouterbox').fadeOut(300, function () {
        $(this).find('.modal-backdrop').fadeOut(250, function () {
          $('body').removeClass('overflowhidden');
          $('.popouterbox .popup-block').removeAttr('style');
          $(this).remove();
        });
      });
      return false;
    });
  }

  ngAfterViewInit() {
    setTimeout(
      () => {
        var $dialogTrigger = $('.poptrigger')
        $dialogTrigger.click(function () {
          var popID = $(this).attr('data-rel');
          $('body').addClass('overflowhidden');
          var winHeight = $(window).height();
          $('#' + popID).fadeIn();
          var popheight = $('#' + popID).find('.popup-block').outerHeight(true);
          if ($('.popup-block').length) {
            var popMargTop = popheight / 2;
            if (winHeight > popheight) {
              $('#' + popID).find('.popup-block').css({
                'margin-top': -popMargTop,
              });
            } else {
              $('#' + popID).find('.popup-block').css({
                'top': 0,
              });
            }
          }
          $('#' + popID).append("<div class='modal-backdrop'></div>");
          $('.popouterbox .modal-backdrop').fadeTo("slow", 0.70);
          $('.custom-pop-box .modal-backdrop').fadeTo("fast", 0.88);
          if (popheight > winHeight) {
            $('.popouterbox .modal-backdrop').height(popheight);
          }
          $('#' + popID).focus();
          return false;
        });
        $('.custom-select').select2({
          minimumResultsForSearch: Infinity
        });
        $('.custom-select2').select2({
          minimumResultsForSearch: Infinity
        });
        if ($('.orderproductdetail').length > 0) {
          const ps = new PerfectScrollbar('.orderproductdetail', {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
          $(window).on("resize", function () { ps.update() });
        }
        if ($('.orderproductdetailsroll').length > 0) {
          const ps1 = new PerfectScrollbar('.orderproductdetailsroll', {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
          $(window).on("resize", function () { ps1.update() });
        }
        $('.add').click(function () {
          if ($(this).prev().val()) {
            $(this).prev().val(+$(this).prev().val() + 1);
          }
        });
        $('.sub').click(function () {
          if ($(this).next().val() > 1) {
            if ($(this).next().val() > 1) {
              $(this).next().val(+$(this).next().val() - 1)
            };
          }
        });
      }, 3500);
  }

  /*
    * _getPickUpAddress, _getDeliveryAddress call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public getUserAddress(type) {
    let info = {
      userId: this.user.id,
      token: this.user.token_key,
    }
    switch (type) {
      case 0:
        this.api._getPickUpAddress(info).subscribe(
          res => {
            let tempData: any = res;
            tempData.status === 1 ? this.pAddressList = tempData.data : console.log(tempData.message);
          },
          err => {
            if (err.status === 401) {
              this.api._conditionalLogout();
            }
          });
        break;
      case 1:
        this.api._getDeliveryAddress(info).subscribe(
          res => {
            let tempData: any = res;
            if (tempData.status === 1) {
              this.dAddressList = tempData.data;
              if (this.dAddressList.length > 0) {
                /* set default address variable */
                this.dAddressList.some(
                  address => {
                    if (address.is_default === "1") this.defaultDAddress = this.addressToString(address);
                  })
              }
            }
            else console.log(tempData.message);
          },
          err => {
            if (err.status === 401) {
              this.api._conditionalLogout();
            }
          });

      default:
        break;
    }
  }
  /*
    * _getBelgiumCitys call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public getBelgiumCitys() {
    this.api._getBelgiumCitys().subscribe(
      res => {
        let tempData: any = res;
        tempData.status === 1 ? this.belgiumCitys = tempData.data : console.log(tempData.message);
      })
  }
  /*
    * _getCartProducts call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public getCartProducts() {
    this.loaderActive = false;
    if (this.cookie.check(window.location.hostname)) {
      this.localCartData = false;
      this.user = JSON.parse(atob(this.cookie.get(window.location.hostname)));
      let info = {
        userId: this.user.id,
        langId: this.langId,
        token: this.user.token_key,
      }
      this.api._getCartProducts(info).subscribe(
        res => {
          let tempData: any = res;
          if (tempData.status === 1) {
            /* cart main obj to use individual property  */
            this.cartObj = tempData.data;
            /* set cart both products data */
            this.cartObj.pickup_data_item.pickup.length > 0 ? this.pproducts = this.cartObj.pickup_data_item.pickup : this.pproducts = [];
            this.cartObj.delivery_data_item.delivery.length > 0 ? this.dproducts = this.cartObj.delivery_data_item.delivery : this.dproducts = [];
            /* get total price */
            let charges: any = 0;
            this.total.pTotal = 0;
            this.total.dTotal = 0;
            if (this.cartObj.pickup_data_item.pickup.length > 0) {
              this.total.pTotal = (this.cartObj.pickup_data_item.sub_total).toFixed(2);
              this.total.tav = (this.cartObj.pickup_data_item.tax).toFixed(2);
              charges = (this.cartObj.pickup_data_item.tax).toFixed(2);
            }
            if (this.cartObj.delivery_data_item.delivery.length > 0) {
              this.total.dTotal = (this.cartObj.delivery_data_item.sub_total).toFixed(2);
              this.total.dcharges = parseFloat(this.cartObj.delivery_charge).toFixed(2);
              charges = parseFloat(charges) + parseInt(this.cartObj.delivery_charge);
            }
            this.total.subTotal = (parseFloat(this.total.pTotal) + parseFloat(this.total.dTotal)).toFixed(2)
            this.total.fTotal = (parseFloat(this.cartObj.de_pi_sub_total) + parseFloat(charges)).toFixed(2);
            /* get user pickup address call */
            this.getUserAddress(0);
            /* get user delivery address call */
            this.getUserAddress(1);

          }
          else { this.pproducts = []; this.dproducts = []; console.log(tempData.message); };
        },
        err => { if (err.status === 401) { this.api._conditionalLogout(); } })
    }
    else {
      if (this.cookie.check(window.location.hostname + 'local')) {
        let localCart = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')))
        this.localCartData = true
        this.pproducts = localCart.cartData.pickup;
        this.dproducts = localCart.cartData.delivery;
        if (this.pproducts.length > 0 || this.dproducts.length > 0) this.getTotal(1);
      }
    }
    setTimeout(() => {
      this.loaderActive = true;
    }, 2000);
  }
  /*
    * _setDefaultAddress call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public setDAddress(dataObj) {
    let info = {
      addressId: dataObj.id,
      isDefault: 1,
      userId: this.user.id,
      token: this.user.token_key,
    }
    this.api._setDefaultAddress(info).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status === 1) {
          $('.close-dialogbox, .modal-backdrop').parents('.popouterbox').fadeOut(300, function () {
            $(this).find('.modal-backdrop').fadeOut(250, function () {
              $('body').removeClass('overflowhidden');
              $('.popouterbox .popup-block').removeAttr('style');
              $(this).remove();
            });
          });
          /* get update user addresses */
          this.getUserAddress(1);
        }
        else console.log(tempData.message);
      },
      err => {
        if (err.status === 401) {
          this.api._conditionalLogout();
        }
      })
  }
  /*
    * _addDeliveryAddress call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public addDeliveryAddress(e) {
    e.preventDefault();
    if (!e.target.cdnastreet.value.match(/[a-zA-Z0-9]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalide street number (only allow char and numbers )",
        e.target.cdnastreet.focus();
    }
    /*     else if (!e.target.cdnacity.value.match(/^[A-Za-z]+$/)) {
          this.errorStatus = false;
          this.errorMSG = "invalide city (only allow char )",
            e.target.cdnacity.focus();
        }
        else if (!e.target.cdnapostal.value.match(/^[0-9]*$/)) {
          this.errorStatus = false;
          this.errorMSG = "invalide postal code (only allow numbers)",
            e.target.cdnapostal.focus();
        }
        else if (!e.target.cdnacountry.value.match(/^[A-Za-z]+$/)) {
          this.errorStatus = false;
          this.errorMSG = "invalide country (only allow char )",
            e.target.cdnacountry.focus();
        } */
    else {
      let info = {
        userId: this.user.id,
        delivery_street_address: e.target.cdnastreet.value,
        delivery_city: e.target.cdnacity.value,
        delivery_country: e.target.cdnacountry.value,
        delivery_postal_code: e.target.cdnapostal.value,
        lang_id: this.langId,
        token: this.user.token_key,
        isCart: 1,
      }
      this.api._addDeliveryAddress(info).subscribe(
        res => {
          let tempData: any = res;
          if (tempData.status === 1) {
            /* Reset form Data */
            e.target.reset();
            this.errorStatus = true;
            this.errorMSG = '';
            $('.close-dialogbox, .modal-backdrop').parents('.popouterbox').fadeOut(300, function () {
              $(this).find('.modal-backdrop').fadeOut(250, function () {
                $('body').removeClass('overflowhidden');
                $('.popouterbox .popup-block').removeAttr('style');
                $(this).remove();
              });
            });
            this.getUserAddress(1);
          }
          else { console.log(tempData.message); }
        },
        err => {
          if (err.status === 401) {
            this.api._conditionalLogout();
          }
        })
    }
  }
  /*
    * _deleteCart call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public removeProduct(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this product now',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let info = {
          userId: this.user.id,
          cartId: e.target.id,
          token: this.user.token_key,
        }
        this.api._deleteCart(info).subscribe(
          res => {
            let tempData: any = res;
            if (tempData.status === 1) {
              console.log('user cart count', this.user.cart_count);
              console.log('api cart count', this.api.cartCount);
              this.api.cartCount = this.api.cartCount - 1;
              this.user.cart_count = this.api.cartCount;
              //this.user.cart_count = this.user.cart_count - 1;
              /* update cookie */
              this.cookie.set(window.location.hostname, btoa(JSON.stringify(this.user)));
              this.getCartProducts();
            }
            else console.log(tempData.message);
          },
          err => {
            if (err.status === 401) {
              this.api._conditionalLogout();
            }
          })
      }
    })

  }
  /*
    * _getOrderDate call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public getOrderDate() {
    this.router.navigate(['select-address']);
    // if (this.pproducts.length === 0 && this.dproducts.length === 0) { return false; }
    // let info = {
    //   userId: this.user.id,
    //   isPickup: this.pproducts.length > 0 ? 1 : 0,
    //   isDeliver: this.dproducts.length > 0 ? 1 : 0,
    //   pickup_id: this.pproducts.length > 0 ? this.pproducts[0].address_id : '',
    //   deliver_id: this.dproducts.length > 0 ? this.dproducts[0].address_id : '',
    //   token: this.user.token_key,
    // }
    // this.api._getOrderDate(info).subscribe(
    //   (res) => {
    //     let tempData: any = res;
    //     if (tempData.status === 1) {
    //       /* For single delivery date available */
    //       if (tempData.data.delivery_date.length == 0) {
    //         /* set set date for both */
    //         this.pDate = tempData.data.pickup_date;
    //         this.dDate = tempData.data.delivery_date;
    //         let info = {
    //           userId: this.user.id,
    //           pickup_date: tempData.data.pickup_date,
    //           delivery_date: '',
    //           token: this.user.token_key,
    //         }
    //         /* create order api call */
    //         this.createOrderAPIMOdel(info);

    //       } /* For multiple delivery date available */
    //       else {
    //         /* set date for pickup */
    //         this.pDate = tempData.data.pickup_date;
    //         this.ddateList = tempData.data.delivery_date;
    //         var popID = 'deliveryDatePopup';
    //         $('body').addClass('overflowhidden');
    //         var winHeight = $(window).height();
    //         $('#' + popID).fadeIn();
    //         var popheight = $('#' + popID).find('.popup-block').outerHeight(true);
    //         if ($('.popup-block').length) {
    //           var popMargTop = popheight / 2;
    //           if (winHeight > popheight) {
    //             $('#' + popID).find('.popup-block').css({
    //               'margin-top': -popMargTop,
    //             });
    //           } else {
    //             $('#' + popID).find('.popup-block').css({
    //               'top': 0,
    //             });
    //           }
    //         }
    //         $('#' + popID).append("<div class='modal-backdrop'></div>");
    //         $('.popouterbox .modal-backdrop').fadeTo("slow", 0.70);
    //         $('.custom-pop-box .modal-backdrop').fadeTo("fast", 0.88);
    //         if (popheight > winHeight) {
    //           $('.popouterbox .modal-backdrop').height(popheight);
    //         }
    //         $('#' + popID).focus();
    //       }
    //     }
    //     else console.log(tempData.message);
    //   },
    //   err => {
    //     if (err.status === 401) {
    //       this.api._conditionalLogout();
    //     }
    //   })
  }
  /*
    * _createOrder call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public createOrderAPIMOdel(obj) {
    this.api._createOrder(obj).subscribe(
      (res) => {
        let tempData: any = res;
        if (tempData.status === 1) {
          let info = {
            userId: this.user.id,
            order_id: tempData.data.order_id,
            lang_id: this.langId,
            token: this.user.token_key,
            device_type: '3'
          }
          /* checkout call */
          this.checkoutAPIMOdel(info);
        }
        else console.log("_createOrder", tempData.message);
      },
      err => {
        if (err.status === 401) {
          this.api._conditionalLogout();
        }
      })
  }

  /*
    * _checkoutOrder call.
    * @param info:obj.
    * @return api response.
    * @author AD.
  */
  public checkoutAPIMOdel(obj) {
    this.api._checkoutOrder(obj).subscribe(
      (res) => {
        let tempData: any = res;
        if (tempData.status === 1) {
          switch (tempData.data.isMollie) {
            case "1":
              window.location.replace(tempData.data.link)
              break;
            case "0":
              console.log("order successfully");
              this.router.navigate(['myorders']);
              break;
            default:
              break;
          }
        }
        else console.log("checkoutOrder", tempData.message);
      }, err => {
        if (err.status === 401) {
          this.api._conditionalLogout();
        }
      })
  }


  /*
    * _updateCart call.
    * @param info:obj. 
    * @return api response.
    * @author AD.
  */
  public geUpdateAmount(e) {
    let info = {
      cartId: e.target.id,
      quantity: e.target.value,
      userId: this.user.id,
      token: this.user.token_key,
    }
    this.api._updateCart(info).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status === 1) { this.getCartProducts(); }
        else console.log("updateCart", tempData.message);
      },
      err => {
        if (err.status === 401) {
          this.api._conditionalLogout();
        }
      })
  }


  /*
    * _getSimilarProducts call.
    * @param info:obj.
    * @return api response.
    * @author AD.
    * for now not use
  */
  public getSimilarProducts(cateid, proid, langid) {
    this.api._getSimilarProducts({ categoryId: cateid, productId: proid, langId: langid }).subscribe(
      res => {
        let tempData: any = res;
        if (tempData.status === 1) { this.sproducts = tempData.data; }
        else console.log(tempData.message);
      })
  }


  /***************************************** HTML Data Set Methods */

  /* get selected city postal code */
  public setPostalCode(e) {
    this.postalCode = $('#belgiumCitysList [value="' + e.target.value + '"]').data('postalcode');
  }

  /* show delivery address as string */
  public addressToString(obj) {
    return `${obj.delivery_street_number}, ${obj.delivery_city}, ${obj.delivery_country}, ${obj.delivery_postal_code}`
  }

  /* get delivery date */
  public getDeliveryDate(e, dataObj) {
    if (e.target.checked) {
      /* set delivery date */
      this.dDate = dataObj
      this.deliveryDateShow = true;
      let info = {
        userId: this.user.id,
        pickup_date: this.pDate,
        delivery_date: this.dDate,
        token: this.user.token_key,
      }
      /* create order api call */
      this.createOrderAPIMOdel(info);
    }
  }

  /* covert float to int */
  public getFloatData(data) {
    return parseFloat(data).toFixed(2);
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }

  /* get locla pickup price */
  public getlocalpprice(price) {
    return this.lptprice += parseFloat(parseFloat(price).toFixed(2));
  }

  /* local cart remove product */
  public localRemove(type, index) {
    let localCart = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')))
    switch (type) {
      case 1:
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this product now',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            localCart.cartData.pickup.splice(index, 1);
            localCart.localCount -= 1;
            this.cookie.set(window.location.hostname + 'local', btoa(JSON.stringify(localCart)));
            this.getCartProducts();
          }
        })
        break;

      case 2:
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this product now',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            localCart.cartData.delivery.splice(index, 1);
            localCart.localCount -= 1;
            this.cookie.set(window.location.hostname + 'local', btoa(JSON.stringify(localCart)));
            this.getCartProducts();
          }
        })
        break;
      default:
        break;
    }
  }

  /* local sync */
  public localSync() {
    if (!this.cookie.check(window.location.hostname)) { this.router.navigate(['login']) }
  }

  /* local total price */
  public getTotal(type) {
    switch (type) {
      case 1:
        this.localTotal.lp = 0, this.localTotal.ld = 0;
        this.pproducts.forEach(lpp => {
          if (lpp.product_quantitywise_price) { this.localTotal.lp += parseFloat(lpp.product_quantitywise_price); }
        });
        this.dproducts.forEach(ldp => {
          if (ldp.product_quantitywise_price) { this.localTotal.ld += parseFloat(ldp.product_quantitywise_price) }
        });
        break;
      case 2:
        return parseFloat(this.localTotal.lp + this.localTotal.ld).toFixed(2);
      case 3:
        let charges = 0;
        if (this.pproducts.length > 0) charges = (this.localTotal.lp * 0.21);
        if (this.dproducts.length > 0) charges = charges + 50;
        return parseFloat((this.localTotal.lp + this.localTotal.ld) + charges).toFixed(2)
      case 4:
        return (this.localTotal.lp * 0.21).toFixed(2)
      default:
        console.log("no select");
        break;
    }
  }

  /* change product quantity */
  public setQuntity(type, e, index) {
    let localCart = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')))
    switch (type) {
      case 1:
        localCart.cartData.pickup[index].product_packages = e.target.value;
        localCart.cartData.pickup[index].product_quantitywise_price = (parseFloat(localCart.cartData.pickup[index].price) * parseInt(e.target.value)).toFixed(2);
        break;
      case 2:
        localCart.cartData.delivery[index].product_packages = e.target.value;
        localCart.cartData.delivery[index].product_quantitywise_price = (parseFloat(localCart.cartData.delivery[index].price) * parseInt(e.target.value)).toFixed(2);
        break;
      default:
        break;
    }
    this.cookie.set(window.location.hostname + 'local', btoa(JSON.stringify(localCart)));
    this.getCartProducts();
  }
}
