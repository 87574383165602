import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import PerfectScrollbar from 'perfect-scrollbar';
import { CookieService } from 'ngx-cookie-service';
declare let $;

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {
  @ViewChild('brandfilter', { static: false }) brandfilter: ElementRef;
  categoryId: string;
  langId: string;
  lastValue = 10;
  subCategorys: any[] = [];
  brands: any[] = [];
  customFilter: any = {};
  mainCategory: string;
  Breadcrumb: any[] = [];
  breadCrumbHierarchy = [];
  baseUrl = '';

  constructor(
    private serviceCall: APIServiceService,
    private cookie: CookieService,
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    private location: Location) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.ActivatedRoute.params.subscribe(params => (this.categoryId = params.token));
    if (sessionStorage.getItem('filter')) { this.customFilter.brands = JSON.parse(atob(sessionStorage.getItem('filter'))).brands.split(','); }
    this.langId = this.serviceCall._languagePass(sessionStorage.getItem('lan'));
    this.baseUrl = `http://stagingurlhub.com/projects/intercarro-tool-for-tiles/public/img/category/`;
    this.serviceCall.isMenuActive = true;
    if (sessionStorage.getItem('breadcrumb')) {
      const temp = JSON.parse(atob(sessionStorage.getItem('breadcrumb')));
      temp.some(
        (cate, i) => {
          if (cate.id == this.categoryId) {
            this.Breadcrumb = temp.splice(0, i + 1);
            return false;
          } else { this.Breadcrumb.push({ id: cate.id, name: cate.name }); }
        });
    }
    this.gettingCategory();
  }

  getBreadCrumbs() {
  }

  ngAfterViewInit() {
    const bps = new PerfectScrollbar(this.brandfilter.nativeElement, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });

    $(window).on('resize', function () {
      bps.update();
    }).resize();
  }

  ngOnDestroy() {
    sessionStorage.setItem('breadcrumb', btoa(JSON.stringify(this.Breadcrumb)));
  }

  /*
    * _gettingCategory call.
    * @param info:obj.
    * @return no-return.
    * @author AD
  */
  public gettingCategory() {
    const info = {
      langId: this.langId,
      brands: this.customFilter.brands.join() || '',
    };
    this.serviceCall._gettingCategory(info).subscribe(
      res => {
        const tempData: any = res;
        if (tempData.status == 1) {
          this.getNlevelSubCategroy(tempData.data, parseInt(this.categoryId), 'sub_category');
          this.getCurrentCategory(tempData.data);
        }
      });
  }

  getCurrentCategory(categories) {
    let category = categories.find(category => category.id === parseInt(this.categoryId));
    if (category && this.breadCrumbHierarchy.filter(cat => cat.id === category.id).length > 0) {
      return;
    }
    if (category) {
      this.breadCrumbHierarchy.push(category);
      return this.breadCrumbHierarchy;
    }
    category = categories.filter((cat: any) => cat.categoryChilds.includes(parseInt(this.categoryId)));
    if (Array.isArray(category) && category.length > 0 && this.breadCrumbHierarchy.filter(cat => cat.id === category[0].id).length > 0) {
      return;
    }
    this.breadCrumbHierarchy.push(category[0]);
    this.getCurrentCategory(category[0].sub_category);
  }

  /*
    * filter in products.
    * @param no-param.
    * @return no-return.
    * @author AD.
  */
  public getFiltervalue(e) {
    e.target.checked ? this.customFilter.brands.push(e.target.dataset.brandid) : this.customFilter.brands.splice(this.customFilter.brands.indexOf(e.target.dataset.brandid), 1);
    sessionStorage.setItem('filter', btoa(JSON.stringify({ brands: this.customFilter.brands.join() })));
    this.gettingCategory();
  }

  /*
    * onScroll data array to next 10 products.
    * @param no-param
    * @return no-return
    * @author AD
  */
  public onScroll() {
    this.lastValue = this.lastValue + 10;
  }

  /*
    * onScrollUp reset data array.
    * @param no-param
    * @return no-return
    * @author AD
  */
  public onScrollUp() {
    this.lastValue = 10;
  }

  /* conditonal route depend */
  public nlevelCheck(obj) {
    return obj.sub_category.length > 0 ? true : false;
  }

  /* n-level category find */
  public getNlevelSubCategroy(arr, itemId, nestingKey) {
    arr.reduce((a, item) => {
      if (a) { return a; }
      if (item.id === itemId) { this.getBreadcrumb(item); this.brands = item.brands; this.subCategorys = item.sub_category; this.mainCategory = item; return false; }
      if (item[nestingKey]) { return this.getNlevelSubCategroy(item[nestingKey], itemId, nestingKey) }
    }, null);
  }

  /* selected value checked after reinitialize filter */
  public setChecked(type, compareValue) {
    switch (type) {
      case 3:
        return this.customFilter.brands.some(brand => brand == compareValue);
      default:
        console.log('not selected default value');
        break;
    }
  }

  /* back to previous page */
  public backTo() {
    this.location.back();
  }

  public resetFilter() {
    this.customFilter.brands = [];
  }

  public getBreadcrumb(data) {
    console.log('data', data);
    if (this.Breadcrumb.length > 0) {
      if (this.Breadcrumb.some(cate => cate.id == data.id)) {
        return false;
      }
      this.Breadcrumb.push({ id: data.id, name: ((data.category_name).split('/')).join('-') });
    } else {
      this.Breadcrumb.push({ id: data.id, name: ((data.category_name).split('/')).join('-') });
    }
  }
}

