import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class APIServiceService {
  deliveryDate: BehaviorSubject<any>;
  private globalApiURL: string = ' http://www.devopserver.com/projects/intercarro-tool-for-tiles/api/v1';
  // http://www.devopserver.com/projects/intercarro-tool-for-tiles/api/v1/
  // 'http://stagingurlhub.com/projects/intercarro-tool-for-tiles/api/v1'
  public isMenuActive = false;
  public imageURL: string = 'http://devopserver.com/projects/intercarro-tool-for-tiles/public/img/category/';
  public isLogin: boolean;

  public cartCount: number = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookie: CookieService) {
    this.isLogin = cookie.check(window.location.hostname);
    this.deliveryDate = new BehaviorSubject(null);
  }


  /*********************************************  common API Model */

  /*
    * return which language was selected by user.
    * @param lang:string
    * @return selected language
    * @author AD
  */
  public _languagePass(lang) {
    switch (lang) {
      case 'nl': return '1';
      case 'fr': return '2';
      default: console.log('no language selected');
        break;
    }
  }

  /*
    * category api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _gettingCategory(info) {
    return this.http.get(this.globalApiURL + '/getproductcategories?lang_id=' + info.langId + '&brand_id=' + info.brands);
  }

  /*
    * gettingWordByCategory api model
    * @param word:staring
    * @return API response
    * @author AD
  */
  public _gettingWordByCategory(info) {
    const body = new HttpParams()
      .set('word', info.word).set('lang_id', this._languagePass(info.langId));
    return this.http.post(this.globalApiURL + '/getwordsuggestion', body);
  }

  /*
    * get pickup address Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _getPickUpAddress(info) {
    const body = new HttpParams().set('user_id', info.userId);
    return this.http.post(this.globalApiURL + '/get-pickup-delivery-address-list', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * new delivery address Api model.
    * @param info:obj.
    * @return API response
    * @author AD
  */
  public _addDeliveryAddress(obj) {
    const body = new HttpParams()
      .set('user_id', obj.userId).set('delivery_street_address', obj.delivery_street_address)
      .set('delivery_city', obj.delivery_city).set('delivery_country', obj.delivery_country)
      .set('delivery_postal_code', obj.delivery_postal_code).set('lang_id', obj.lang_id)
      .set('is_cart', obj.isCart);
    return this.http.post(this.globalApiURL + '/add-deliveryaddress', body, { headers: new HttpHeaders().set('token-key', obj.token) });
  }

  /*
    * get product name || category name by user input.
    * @param a,b:string
    * @return API response
    * @author AD
  */
  public _searchProductList(info) {
    const body = new HttpParams().set('word', info.word).set('lang_id', info.lang_id).set('user_id', info.user_id);
    return this.http.post(this.globalApiURL + '/getsearchproductslist', body);
  }

  /*
    * getSimilarProducts api model.
    * @param info:obj
    * @return API response
    * @author AD
  */
  public _getSimilarProducts(info) {
    const body = new HttpParams().set('category_id', info.categoryId).set('product_id', info.productId)
      .set('lang_id', info.langId);
    return this.http.post(this.globalApiURL + '/similarproducts', body);
  }

  /*
    * _conditionalLogout api model.
    * @param no-param
    * @return API response
    * @author AD
  */
  public _conditionalLogout() {
    this.cookie.delete(window.location.hostname);
    this.isLogin = false;
    this.router.navigate(['/login']);
  }

  /*********************************************  login component API Model */

  /*
    * login api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _userLogin(info) {
    const body = new HttpParams()
      .set('email', info.email).set('password', info.password);
    return this.http.post(this.globalApiURL + '/check-login', body);
  }

  /*********************************************  registration component API Model */

  /*
    * getBelgiumCitys api model.
    * @param no-param
    * @return API response
    * @author AD
  */
  public _getBelgiumCitys() {
    return this.http.get(this.globalApiURL + '/getpostalcodes');
  }

  /*
    * registration API model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _registration(info) {

    const body = new HttpParams()
      .set('tab_type', info.type).set('name', info.name || '')
      .set('street_number', info.street || '').set('city', info.city || '')
      .set('tav_number', info.tav || '').set('country', info.country || '')
      .set('telephone', info.phone || '').set('email', info.email || '')
      .set('company_name', info.company || '').set('password', info.password)
      .set('postal_code', info.postal || '').set('email_tav', info.tav || '');

    return this.http.post(this.globalApiURL + '/register-user', body);
  }

  /*********************************************  forgot component API Model */

  /*
    * forgot password api modle.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _forgotPassword(info) {
    const body = new HttpParams().set('email', info.email).set('device_type', info.device_type);
    return this.http.post(this.globalApiURL + '/forgot-password', body);
  }

  /*********************************************  resetPassword component API Model */

  /*
    * reset password api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _resetPassword(info) {
    const body = new HttpParams()
      .set('email', info.email).set('password', info.npassword);
    return this.http.post(this.globalApiURL + '/update-password', body);
  }

  /*********************************************  home component API Model */

  /*
    * getAllBrands api model.
    * @param no-param
    * @return API response
    * @author AD
  */
  public _getAllBrands() {
    return this.http.get(this.globalApiURL + '/getbrandfilters');
  }

  /*********************************************  products component API Model */

  /*
    * get all products api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _getProductByCategory(info) {

    const body = new HttpParams()
      .set('category_id', info.category_id).set('page_number', info.page_number)
      .set('lang_id', info.lang_id).set('min_price', info.min_price)
      .set('max_price', info.max_price).set('nominal_format', info.nominal_format)
      .set('color', info.color).set('brand', info.brand)
      .set('user_id', info.user_id);

    return this.http.post(this.globalApiURL + '/gethomeproducts', body, { headers: new HttpHeaders().set('device-type', '3') });
  }

  /*
    * get filter characteristics api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _getfiltercharacteristics(info) {
    const body = new HttpParams().set('lang_id', info.lang_id).set('category_id', info.category_id);
    return this.http.post(this.globalApiURL + '/get-filter-characteristics', body);
  }

  /*********************************************  product-view component API Model */

  /*
    * product details api model.
    * @param info:obj.
    * @return API response
    * @author AD
  */
  public _productInfo(info) {
    const body = new HttpParams().set('product_id', info.productId).set('lang_id', info.langId).set('user_id', info.user_id);
    return this.http.post(this.globalApiURL + '/product-detail', body);
  }

  /*
    * add to cart api model.
    * @param info.
    * @return API response
    * @author AD
  */
  public _addTOCart(info) {
    const body = new HttpParams()
      .set('product_id', info.productId).set('user_id', info.userId)
      .set('quantity', info.quantity).set('tab_type', info.tab_type)
      .set('address_id', info.address_id).set('unity', info.unity);
    return this.http.post(this.globalApiURL + '/add-to-cart', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*********************************************  profile component API Model */

  /*
    * user profile info.
    * @param info:obj.
    * @return API response
    * @author AD
  */
  public _userViewProfile(info) {
    const body = new HttpParams().set('user_id', info.userId);
    return this.http.post(this.globalApiURL + '/view-profile', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /* Not use For Now */
  public _updateProfile(a, b, c, d, f, g, token) {
    const body = new HttpParams()
      .set('role_id', a).set('name', b)
      .set('telephone', c).set('user_id', d)
      .set('profile_image', f).set('lang_id', g);
    return this.http.post(this.globalApiURL + '/update-profile', body, { headers: new HttpHeaders().set('token-key', token.toString()) });
  }

  /*
    * set default address api model.
    * @param info:obj,
    * @return API response
    * @author AD
  */
  public _setDefaultAddress(info) {
    const body = new HttpParams()
      .set('delivery_address_id', info.addressId).set('is_default_delivery_address', info.isDefault)
      .set('user_id', info.userId);
    return this.http.post(this.globalApiURL + '/set-default-deliveryaddress', body, { headers: new HttpHeaders().set('token-key', info.token.toString()) });
  }

  /*********************************************  cart component API Model */

  /*
    * get delivery address Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _getDeliveryAddress(info) {
    return this.http.get(this.globalApiURL + '/get-all-deliveryaddress-list?user_id=' + info.userId, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * get cart products Api model.
    * @param info:obj
    * @return API response
    * @author AD
  */
  public _getCartProducts(info) {
    const body = new HttpParams()
      .set('user_id', info.userId).set('lang_id', info.langId)
      .set('tab_type', '3');
    return this.http.post(this.globalApiURL + '/get-all-cart-list', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * delete product Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _deleteCart(info) {
    return this.http.delete(this.globalApiURL + '/delete-cart?user_id=' + info.userId + '&&cart_id=' + info.cartId, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * get order date Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _getOrderDate(info) {
    const body = new HttpParams()
      .set('user_id', info.userId).set('isPickup', info.isPickup)
      .set('isDeliver', info.isDeliver).set('pickup_id', info.pickup_id)
      .set('deliver_id', info.deliver_id);
    return this.http.post(this.globalApiURL + '/getorderdate', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * create order Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _createOrder(info) {
    const body = new HttpParams()
      .set('user_id', info.userId).set('delivery_date', info.delivery_date)
      .set('pickup_date', info.pickup_date)
    return this.http.post(this.globalApiURL + '/create-order', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * checkoutOrder Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _checkoutOrder(info) {
    const body = new HttpParams()
      .set('user_id', info.userId).set('order_id', info.order_id)
      .set('lang_id', info.lang_id).set('device_type', info.device_type);
    return this.http.post(this.globalApiURL + '/checkout', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * cart product update api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _updateCart(info) {
    const body = new HttpParams()
      .set('cart_id', info.cartId).set('quantity', info.quantity)
      .set('user_id', info.userId);
    return this.http.post(this.globalApiURL + '/update-cart', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*
    * local cart product sync api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _localcartSync(info) {
    const body = new HttpParams()
      .set('userId', info.userId).set('cartData', info.cartData);
    return this.http.post(this.globalApiURL + '/add-to-cart-login', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*********************************************  myorder component API Model */

  /*
    * userOrderList Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _userOrderList(info) {
    const body = new HttpParams().set('user_id', info.userId)
    return this.http.post(this.globalApiURL + '/vieworderlist', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*********************************************  vieworder component API Model */

  /*
    * getOrderDetails Api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _getOrderDetails(info) {
    const body = new HttpParams().set('user_id', info.userId).set('order_id', info.orderId);
    return this.http.post(this.globalApiURL + '/vieworderdetail', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*********************************************  change_password component API Model */

  /*
    * change password api model.
    * @param info:obj.
    * @return API response.
    * @author AD.
  */
  public _changePassword(info) {
    const body = new HttpParams()
      .set('user_id', info.userId).set('old_password', info.oldPassword)
      .set('new_password', info.newPassword);
    return this.http.post(this.globalApiURL + '/change-password', body, { headers: new HttpHeaders().set('token-key', info.token) });
  }

  /*********************************************  Order success component API Model */

  public _viewConfirmOrderDetail(info) {
    const body = new HttpParams()
      .set('user_id', info.userId)
      .set('order_id', info.orderId)
      .set('lang_id', info.lang_id);
    return this.http.post(this.globalApiURL + '/viewconfirmedorderdetail', body,
      {
        headers: new HttpHeaders().set('token-key', info.token)
      })
  }

  /*********************************************  Get Cart Count API Model */

  public _getCartCount(info) {
    const body = new HttpParams()
      .set('user_id', info.userId);
    return this.http.post(this.globalApiURL + '/get-all-cart-count', body,
      {
        headers: new HttpHeaders().set('token-key', info.token)
      })
  }
  /*********************************************  Get Pickup and Delivery Address API Model */

  public _getAddress(info) {
    const body = new HttpParams()
      .set('user_id', info.userId)
      .set('lang_id', info.lang_id);
    return this.http.post(this.globalApiURL + '/get-all-pickup-delivery-address-list', body,
      {
        headers: new HttpHeaders().set('token-key', info.token)
      })
  }
}



