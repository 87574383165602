import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/api.service';
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
declare let $;
@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.css']
})
export class RegistrationPageComponent implements OnInit {
  selectCountry: string = 'BE';
  belgiumCitys: any[] = [];
  selectCityPostalCode: string;
  errorStatus: boolean = true;
  errorMSG: string = "";
  cartData: any[] = [];
  constructor(
    private serviceCall: APIServiceService,
    private cookie: CookieService,
    private router: Router) { }

  ngOnInit() {
    var $win = $(window);
    var $doc = $(document);
    var $winW = function () {
      return $(window).width();
    };
    var $winH = function () {
      return $(window).height();
    };
    var $screensize = function (element) {
      $(element).width($winW()).height($winH());
    };
    var screencheck = function (mediasize) {
      if (typeof window.matchMedia !== "undefined") {
        var screensize = window.matchMedia("(max-width:" + mediasize + "px)");
        if (screensize.matches) {
          return true;
        } else {
          return false;
        }
      } else { // for IE9 and lower browser
        if ($winW() <= mediasize) {
          return true;
        } else {
          return false;
        }
      }
    };

    /* Tab Content box 
      ---------------------------------------------------------------------*/
    var tabBlockElement = $('.tab-data');
    let self = this;
    $(tabBlockElement).each(function () {
      var $this = $(this),
        tabTrigger = $this.find(".tabnav li"),
        tabContent = $this.find(".tabcontent");
      var textval = [];
      tabTrigger.each(function () {
        textval.push($(this).text());
      });
      $this.find(tabTrigger).first().addClass("active");
      $this.find(tabContent).first().show();
      $(tabTrigger).on('click', function () {
        /* hide error during tab change */
        $('.tab-container input').val('');
        self.errorStatus = true;
        self.errorMSG = "";
        /* Reset to initial stage after tab change*/
        $('.signupherebtn').parents('.signuphere').show();
        $('.signupherebtn').parents('.signuphere').next().hide();
        $(tabTrigger).removeClass("active");
        $(this).addClass("active");
        $(tabContent).hide().removeClass('visible');
        var activeTab = $(this).find("a").attr("data-rel");
        $this.find('#' + activeTab).fadeIn('normal').addClass('visible');
        return false;
      });
      var responsivetabActive = function () {
        if (screencheck(767)) {
        } else {
          if ($('.tabMobiletrigger').length) {
            $('.tabMobiletrigger').remove();
            tabTrigger.removeClass("active");
            $this.find(tabTrigger).removeClass("active").first().addClass('active');
            $this.find(tabContent).hide().first().show();
          }
        }
      };
      $(window).on('resize', function () {
        if (!$this.hasClass('only-tab')) {
          responsivetabActive();
        }
      }).resize();
    });
    $('.signupherebtn').click(function () {
      /* hide error during tab change */
      $('.tab-container input').val('');
      self.errorStatus = true;
      self.errorMSG = "";
      self.selectCountry = 'BE'
      self.selectCityPostalCode = '';
      $(this).parents('.signuphere').hide();
      $(this).parents('.signuphere').next().show();
    });
    this.getBelgiumCitys();
  }

  /*
    * _registration call.
    * @param info:obj. 
    * @return api response.
    * @author AD.
  */
  public registration(type, e) {
    e.preventDefault();
    switch (type) {
      case 1:
        this.privateUser(e);
        break;
      case 2:
        this.professionalOLD(e);
        break;
      case 3:
        this.professionalNEW(e);
        break;
      default:
        console.log('type note selected');
        break;
    }
  }

  public privateUser(e) {
    if (!e.target.priname.value.match(/^[A-Za-z ]+$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid name (only char allow)";
      e.target.priname.focus();
    }
    else if (!e.target.priphone.value.match(/^[0-9]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid phone number (only number allow)";
      e.target.priphone.focus();
    }
    else if (!e.target.pristreet.value.match(/[a-zA-Z0-9 ]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid street number (only char and numbers allow)";
      e.target.pristreet.focus();
    }
    else if (!e.target.pricity.value.match(/[a-zA-Z0-9 ]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid city name (only char allow)";
      e.target.pricity.focus();
    }
    else if (!e.target.pripostal.value.match(/^[0-9]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid postal code (only numbers allow)";
      e.target.pripostal.focus();
    }
    else if (!e.target.pricountry.value.match(/^[A-Za-z ]+$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid country name (only char allow)";
      e.target.pricountry.focus();
    }
    else if (!e.target.pripassword.value.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/) || !e.target.pricpassword.value.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid password && confirm password (not match requirement)";
      e.target.pripassword.focus();
    }
    else if (!e.target.pripassword.value.match(e.target.pricpassword.value)) {
      this.errorStatus = false;
      this.errorMSG = "password not match with confirm password";
      e.target.pripassword.focus();
    }
    else {
      let info = {
        e: e,
        type: 1,
        name: e.target.priname.value,
        email: e.target.priemail.value,
        street: e.target.pristreet.value,
        city: e.target.pricity.value,
        postal: e.target.pripostal.value,
        country: e.target.pricountry.value,
        password: e.target.pripassword.value,
        phone: e.target.priphone.value,
      }
      this.APICall(info);
    }

  }

  public professionalOLD(e) {
    if (!e.target.proopassword.value.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/) || !e.target.proocpassword.value.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid password && confirm password (not match requirement)";
      e.target.proopassword.focus();
    }
    else if (!e.target.proopassword.value.match(e.target.proocpassword.value)) {
      this.errorStatus = false;
      this.errorMSG = "password not match";
      e.target.proopassword.focus();
    }
    else {
      let info = {
        e: e,
        type: 3,
        tav: e.target.prooidtav.value,
        password: e.target.proopassword.value,
      }
      this.APICall(info)
    }
  }

  public professionalNEW(e) {
    if (!e.target.pronname.value.match(/^[A-Za-z ]+$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid name (only char allow)";
      e.target.pronname.focus();
    }
    else if (!e.target.pronphone.value.match(/^[0-9]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid phone number (only number allow)";
      e.target.pronphone.focus();
    }
    else if (!e.target.proncompany.value.match(/^[A-Za-z ]+$/)) {
      this.errorStatus = false;
      this.errorMSG = "inavlid company name (only char allow)";
      e.target.proncompany.focus();
    }
    /*   else if (!e.target.prontav.value.match(/[a-zA-Z0-9 ]*$ /)) {
        this.errorStatus = false;
        this.errorMSG = "invalid tav number (only char and numbers allow)";
        e.target.prontav.focus();
      } */
    else if (!e.target.pronstreet.value.match(/[a-zA-Z0-9 ]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid street number (only char and numbers allow)";
      e.target.pronstreet.focus();
    }
    else if (!e.target.proncity.value.match(/[a-zA-Z0-9 ]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid city name (only char allow)";
      e.target.proncity.focus();
    }
    else if (!e.target.pronpostal.value.match(/^[0-9]*$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid postal code (only numbers allow)";
      e.target.pronpostal.focus();
    }
    else if (!e.target.proncountry.value.match(/^[A-Za-z ]+$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid country name (only char allow)";
      e.target.proncountry.focus();
    }
    else if (!e.target.pronpassword.value.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/) || !e.target.proncpassword.value.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/)) {
      this.errorStatus = false;
      this.errorMSG = "invalid password && confirm password (not match requirement)";
      e.target.pronpassword.focus();
    }
    else if (!e.target.pronpassword.value.match(e.target.proncpassword.value)) {
      this.errorStatus = false;
      this.errorMSG = "password not match with confirm password";
      e.target.pronpassword.focus();
    }
    else {
      let info = {
        e: e,
        type: 2,
        name: e.target.pronname.value,
        email: e.target.pronemail.value,
        phone: e.target.pronphone.value,
        company: e.target.proncompany.value,
        tav: e.target.prontav.value,
        street: e.target.pronstreet.value,
        city: e.target.proncity.value,
        postal: e.target.pronpostal.value,
        country: e.target.proncountry.value,
        password: e.target.pronpassword.value,
      }
      this.APICall(info)
    }
  }

  public APICall(obj) {
    this.serviceCall._registration(obj).subscribe(
      (res) => {

        let tempData: any = res;
        if (tempData.status === 1) {
          /* Hide Error */
          console.log('response', tempData);
          console.log('obj details', obj);
          /* form fields reset */
          obj.e.target.reset();
          this.login(obj);
          //this.router.navigate(['/login']);
        }
        else {
          /* Show Error */
          this.errorStatus = false;
          this.errorMSG = tempData.message;
        }
      })
  }
  /*
    * _userLogin call.
    * @param event:obj 
    * @return api response.
  */
  public login(e) {
    e.e.preventDefault();
    if (e.password.match(/^(?=.*[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z])(?=.*[0-9].*[0-9].*[0-9].*[0-9]).{8,}$/)) {
      let info = {
        email: e.email,
        password: e.password
      }
      this.serviceCall._userLogin(info).subscribe(
        (res) => {
          let tempData: any = res;
          if (tempData.status === 1) {

            /* Error hide */
            this.errorStatus = true;
            this.errorMSG = "";

            /* user data store in cookie using hostname */
            let user = tempData.data;
            if (this.cookie.check(window.location.hostname + 'local')) {

              let local = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')));
              /* Local pickup */
              local.cartData.pickup.forEach(
                product => {
                  this.cartData.push({
                    product_id: product.lpid,
                    quantity: parseInt(product.product_packages),
                    tab_type: 1,
                    address_id: 0,
                    unity: product.unity
                  })
                }
              );

              /* Local delivery */
              local.cartData.delivery.forEach(
                product => {
                  this.cartData.push({
                    product_id: product.lpid,
                    quantity: parseInt(product.product_packages),
                    tab_type: 2,
                    address_id: 0,
                    unity: product.unity
                  })
                }
              );
              /* local cart sync with user cart */
              this.serviceCall._localcartSync({ userId: tempData.data.id, cartData: JSON.stringify(this.cartData), token: tempData.data.token_key }).subscribe(
                res => {
                  let tempData: any = res;
                  if (tempData.status == 1) {
                    user.cart_count = tempData.totalCartCount;
                    this.cartData = [];
                    this.cookie.delete(window.location.hostname + 'local');
                  }
                })
            }
            let info = {
              userId: user.id,
              token: user.token_key,
            }
            this.serviceCall._getCartCount(info).subscribe((data: any) => {
              console.log('cart count', data);
              this.serviceCall.cartCount = data.data.cart_count;
              let cookieData = JSON.parse(atob(this.cookie.get(window.location.hostname)));
              cookieData.cart_count = this.serviceCall.cartCount;
              this.cookie.set(window.location.hostname, btoa(JSON.stringify(cookieData)));
            })
            this.cookie.set(window.location.hostname, btoa(JSON.stringify(user)));
            Swal.fire({
              icon: 'success',
              title: 'Registered Successfully',
              showConfirmButton: false,
              timer: 1500
            })
            this.router.navigate(['home']);
          }
          else {
            /* Error show */
            this.errorMSG = tempData.message;
            this.errorStatus = false;
          }
        })
    }
    else {
      /* Error show */
      this.errorMSG = "Password pattern not match";
      this.errorStatus = false;
      e.password.focus();
    }
  }

  /*
    * _getBelgiumCitys call.
    * @param event:obj 
    * @return api response.
    * @author AD.
  */
  public getBelgiumCitys() {
    this.serviceCall._getBelgiumCitys().subscribe(
      res => {
        let tempData: any = res;
        tempData.status === 1 ? this.belgiumCitys = tempData.data : console.log(tempData.message);
      })
  }

  /*
    * get postal code for select city in html.
    * @param event:obj 
    * @return api response.
    * @author AD.
  */
  public getPostalCode(e) {
    this.selectCityPostalCode = $('#belgiumCitysList [value="' + e.target.value + '"]').data('postalcode');
  }

}
