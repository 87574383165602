import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { APIServiceService } from 'src/app/api/api.service';
declare let $;
@Component({
  selector: 'app-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.css']
})
export class HeaderPageComponent implements OnInit {
  username: string;
  categorys: any = [];
  suggestionList: any[] = [];
  useLang: string;
  constructor(private translate: TranslateService,
    public API: APIServiceService,
    private router: Router,
    private cookie: CookieService) { }

  ngOnInit() {
    this.getCategories();
    this.loginStatus();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.loginStatus();
  // }

  ngAfterViewInit() {
    ///this._getCartCount();
    let $winW = function () { return $(window).width(); };
    let screencheck = function (mediasize) {
      if (typeof window.matchMedia !== "undefined") {
        let screensize = window.matchMedia("(max-width:" + mediasize + "px)");
        return screensize.matches ? true : false;
      }
      else return $winW() <= mediasize ? true : false;
    };
    /* Mobile menu click */
    $(document).on('click', "#menu", function () {
      $(this).toggleClass('menuopen');
      $(this).next('ul').slideToggle('normal');
      return false;
    });
    /* Mobile menu click */

    /* slide up when redirect */
    $(document).on('click', "#mainmenu li a", function () {
      $(this).parents('.subfilter').slideUp('normal');
      $(this).parents('.subfilter').prev('a.active').removeClass('active');
      $(this).parents('.subcategory').slideUp('normal');
      $(this).parents('.subcategory').prev('.droparrow.dropopen').removeClass('dropopen');
    });
    /* slide up when redirect */

    $(document).on('click', ".droparrow", function () {
      if ($(window).width() > 1023) {
        /* More Menu if Open */
        if ($('.morenavdropdown > a').hasClass('active')) {
          $('.morenavdropdown > a').toggleClass('active');
          $('.morenavdropdown > a').next().slideUp();
        }
        if (!$('.mainnavbar').find('.droparrow').hasClass('dropopen')) {
          $(this).toggleClass('dropopen');
          $(this).parent('.dropdownbox').find('.dropdownarea').slideDown();
        }
        else if ($('.mainnavbar').find('.droparrow').hasClass('dropopen')) {
          /* If Self menu click */
          if ($('.mainnavbar').find('.droparrow.dropopen')[0] == $(this)[0]) {
            $(this).next().slideToggle('normal');
            $(this).toggleClass('dropopen');
            return false;
          }
          else {
            /* if self menu not click */
            $('.mainnavbar').find('.droparrow.dropopen').next().slideUp();
            $('.mainnavbar').find('.droparrow.dropopen').toggleClass('dropopen');
            $(this).next().slideToggle('normal');
            $(this).toggleClass('dropopen');
          }
          /* console.log($('.mainnavbar').find('.droparrow.dropopen').next().slideToggle('normal')); */
        }
      }
      else if ($(window).width() < 1023) {
        $(this).toggleClass('dropopen');
        $(this).parent('.dropdownbox').find('.dropdownarea').slideToggle('normal');
      }
      return false;
    });
    $(document).on('click', ".myprofilebtn", function () {
      $(this).parent('.profile').find('.myprofiledrop').slideToggle('normal');
      return false;
    });
    $(".mainnavbar > li > ul").parent('li').addClass('hasnav');
    $(".mainnavbar > li.hasnav").prepend('<span class="navtrigger">+</span>');
    $(".mainnavbar li").hover(function () {
      if (!screencheck(1023)) {
        $(this).addClass('current');
        $(this).find('ul').not(':animated').fadeIn('normal');
      }
    }, function () {
      if (!screencheck(1023)) {
        $(this).removeClass('current');
        $(this).find('ul').fadeOut('fast');
      }
    });
    $(".navtrigger").on('click', function () {
      if (screencheck(1023)) {
        if ($(this).parents("li.hasnav").find("ul").is(':hidden')) {
          $(this).addClass('open');

          $(this).parents("li.hasnav").find("ul").slideDown('normal');
        } else {
          $(this).removeClass('open');
          $(this).parents("li.hasnav").find("ul").slideUp('normal');
        }
        return false;
      }
    });
    $(window).on("resize", function () {
      var $headerHight = $("#header").outerHeight();
      $("#wrapper").css("padding-top", $headerHight + "px");
    }).resize();
    let self = this;
    setTimeout(() => {
      $('.custom-select').select2({ minimumResultsForSearch: Infinity });
      $('.custom-select').on('change', function (e) { self.lanTranslate(e); })
      $(document).on('click', ".searchicon", function (e) {
        event.stopImmediatePropagation();
        $(this).prev('.searchinput').toggleClass('searchshow');
        return false;
      });
      $('.searchinput').click(function () {
        // $(this).addClass('searchshow');
        event.stopImmediatePropagation();
      })
      $('.searchinput').focus(function () {
        // $(this).addClass('searchshow');
        event.stopImmediatePropagation();
      })
      if ($(window).width() < 1023) { $('.moreinnermenu').insertAfter('.mainnavbar li:last-child'); }
      if ($('.matchheightbox').length) { $('.matchheightbox').matchHeight(); }
      /* Main Navigation Sticky */
      var $headerHight = $("#header").outerHeight();
      $("#wrapper").css("padding-top", $headerHight + "px");
      var intialtop = $(document).scrollTop(), headerheight = $("#header").outerHeight();
      if ($('#more-nav .cols .col').length) { $('#more-nav .cols .col').matchHeight(); }
      $(window).scroll(function () {
        var afterscrolltop = $(document).scrollTop();
        if (afterscrolltop > headerheight) { $("#header").addClass("navhide"); }
        else { $("#header").removeClass("navhide"); }
        if (afterscrolltop > intialtop) { $("#header").removeClass("navshow"); }
        else { $("#header").addClass("navshow"); }
        intialtop = $(document).scrollTop();
      });
      /* Main Navigation Sticky */
      $(document).on('click', function () {
        $('#mainmenu li a').parents('.subcategory').slideUp('normal');
        $('#mainmenu li a').parents('.subcategory').prev('.droparrow.dropopen').removeClass('dropopen');
        $('.myprofiledrop').slideUp('normal');
        $('.subfilter').slideUp();
        $('.morenavdropdown > a.active').removeClass('active');
        $(".searchicon").prev('.searchinput').removeClass('searchshow');
      });
      $(window).on('resize', function () {
        if (screencheck(1023)) {
          if (!$('#menu').length) {
            $('#mainmenu').prepend('<a href="javascript:void(0);" id="menu" class="menulines-button"><span class="menulines"></span></a>');
          }
        } else {
          $("#menu").remove();
        }
      }).resize();
    }, 1000);
    setInterval(function () {
      $('.bgimg img').each(function () {
        let imgSrc = $(this).attr('src');
        $(this).hide();
        $(this).parents('.bgimg').css('background-image', 'url(' + imgSrc + ')');
      });
      self.loginStatus();
    }, 100);
  }

  /*
    * _gettingCategory call.
    * @param  lang-type:string 
    * @return api-response
    * @author AD
  */
  public getCategories() {
    let info = {
      langId: this.API._languagePass(sessionStorage.getItem('lan')),
      brands: '',
    }
    this.API._gettingCategory(info).subscribe(
      res => {
        let tempData: any = res;
        tempData.status == 1 ? this.categorys = tempData.data : console.log("header", tempData.message);
      });
  }

  /*
    * _gettingWordByCategory call.
    * @param word: string.
    * @return api response.
    * @author AD.
  */
  public categoryWords(e) {
    e.preventDefault();
    this.API._gettingWordByCategory({ word: e.target.value, langId: this.useLang }).subscribe(res => {
      let tempData: any = res;
      if (tempData.status == 1) { if (tempData.data.length > 0) this.suggestionList = tempData.data; }
      else console.log(tempData.message);
    });
  }

  /*
    * set username to show in html.
    * @param  no-param 
    * @return no-return
    * @author AD
  */
  public loginStatus() {
    console.log('login');
    this.useLang = sessionStorage.getItem('lan');
    let localCart: any = {};
    if (this.cookie.check(window.location.hostname + 'local')) {
      localCart = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')));
      this.API.cartCount = localCart.localCount;
    }
    if (this.cookie.check(window.location.hostname)) {
      this.API.isLogin = true;

      if (this.cookie.check(window.location.hostname)) {
        let token = JSON.parse(atob(this.cookie.get(window.location.hostname)));
        this.username = token.name;
        this.API.cartCount = token.cart_count + (localCart.localCount || 0);
      }
    }
    else {
      this.API.isLogin = false;
      this.username = undefined;
    }
    /* if localcart exist  */
    // if (this.cookie.check(window.location.hostname + 'local')) {
    //   let localCart = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')));
    //   this.API.cartCount = localCart.localCount;
    // }
  }

  /*
    * logout user to remove data from cookie.
    * @param  no-param 
    * @return no-return
    * @author AD
  */
  public Logout() {
    this.cookie.delete(window.location.hostname);
    this.router.navigate(['/home']);
    if (this.cookie.check(window.location.hostname + 'local')) {
      let localCart = JSON.parse(atob(this.cookie.get(window.location.hostname + 'local')));
      this.API.cartCount = localCart.localCount;
    } else {
      this.API.cartCount = 0;
    }
  }

  /*
    * redirect to search-product page with data pass in url.
    * @param no-param.
    * @return no-return
    * @author AD
  */
  public toCategoryPage(e) {
    e.preventDefault();
    if (e.target.headerSearch.value != "") {
      e.target.children[1].click();
      this.router.navigate(["Search-product", e.target.headerSearch.value]);
      e.target.reset();
    }
  }

  /*
    * translat the page by which one language selected by user.
    * @param  no-param 
    * @return no-return
    * @author AD
  */
  public lanTranslate(e) {
    if (e.target.value.match('nl')) {
      this.translate.use('nl');
      sessionStorage.setItem('lan', 'nl');
    }
    else if (e.target.value.match('fr')) {
      this.translate.use('fr');
      sessionStorage.setItem('lan', 'fr');
    }
  }

  /* html data get || set methods */
  public getArray(data1, data2) {
    let tempArray = [];
    tempArray.push(data1);
    if (data2) tempArray.push(data2);
    return tempArray;
  }

  /* html data get || set methods */
  public moreMenuShow() {
    if ($(window).width() > 1023) {
      $('.dropdownarea').slideUp(); $('.droparrow').removeClass('dropopen');
      $('.morenavdropdown > a').toggleClass('active'); $('.morenavdropdown > a').next().slideToggle('normal');
      event.stopPropagation();

    }
  }

  /* conditonal route depend */
  public nlevelCheck(obj) {
    return obj.sub_category.length > 0 ? true : false;
  }
}
