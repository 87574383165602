import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-fail',
  templateUrl: './order-fail.component.html',
  styleUrls: ['./order-fail.component.css']
})
export class OrderFailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
