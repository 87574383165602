import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
/* Extra Components */
import { AppComponent } from './app.component';
import { RegistrationPageComponent } from './components/registration/registration-page.component';
import { ForgotPassPageComponent } from './components/forgetPassword/forgot-pass-page.component';
import { ResetpassPageComponent } from './components/resetPassword/resetpass-page.component';
import { LoginPageComponent } from './components/login/login-page.component';
import { ChangePassComponent } from './components/changePassword/change-pass.component';
import { HeaderPageComponent } from './components/header/header-page.component';
import { HomePageComponent } from './components/home/home-page.component';
import { SubCategoryComponent } from './components/subCategory/sub-category.component';
import { CategoryPageComponent } from './components/category/category-page.component';
import { ProductViewComponent } from './components/productDetails/product-view.component';
import { ProfilePageComponent } from './components/userProfile/profile-page.component';
import { OrderPageComponent } from './components/order/order-page.component';
import { CartDeliveryComponent } from './components/cartDelivery/cart-delivery.component';
import { SearchProductComponent } from './components/search/search-product.component';
import { ViewOrderComponent } from './components/view-order/view-order.component';
import { PageNotFoundPageComponent } from './components/pageNotFound/page-not-found-page.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { FooterComponent } from './components/footer/footer.component';
/* Extra Modules */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CookieService } from 'ngx-cookie-service';
import { OrderFailComponent } from './components/order-fail/order-fail.component';
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/languages/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderPageComponent,
    FooterComponent,
    CategoryPageComponent,
    ProductViewComponent,
    LoginPageComponent,
    RegistrationPageComponent,
    ProfilePageComponent,
    ForgotPassPageComponent,
    ResetpassPageComponent,
    ChangePassComponent,
    OrderPageComponent,
    CartDeliveryComponent,
    SearchProductComponent,
    PageNotFoundPageComponent,
    SubCategoryComponent,
    ViewOrderComponent,
    OrderSuccessComponent,
    OrderFailComponent,
    DeliveryAddressComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
